import Deco from "security/deco"
export default function authHeader() {
  const user = JSON.parse(Deco(localStorage.getItem('user')));

  if (user && user.token) {
    console.log(user.token)
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}
