import { Button, CardTitle } from "reactstrap";


import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import Invoice from "views/Invoice"
import DonHang_GHTK from "views/DonHang_GHTK"
import DonHang_TMDT from "views/DonHang_TMDT"

import NhanVienChi from "views/NhanVienChi"

import Deco from "../security/deco"

import DashboardX from "views/Dashboard.js";

import PhiVanChuyen from "views/PhiVanChuyen.js";
import NhanVien from "views/NhanVien";

import TaiChinh from "views/TaiChinh"
import TaiChinh_Ben from "views/TaiChinh_Ben"
import TaiChinh_Det from "views/TaiChinh_Det"

import NhapHang from "views/NhapHang"
import TienBanHang from "views/TienBanHang"

import SanPham from "views/SanPham"
import BangGiaSi from "views/BangGiaSi"
import GioHangGiaSi from "views/GioHangGiaSi"
import ChinhSachBaoHanh from "views/ChinhSachBaoHanh"

import moment from 'moment'
import routes from "routes.js";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      newRoutes: [],


      monthSelected: localStorage.getItem('month'),
      yearSelected: localStorage.getItem('year')
    };

    this.monthYearChange = React.createRef();
    this.mainPanel = React.createRef();
  }
  componentDidMount() {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(this.mainPanel.current);
    //   document.body.classList.toggle("perfect-scrollbar-on");
    // }
    this.logged()
    this.administratorPrivileges()

    this.setState({ newRoutes: routes })
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }
  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  administratorPrivileges() {
    const user = JSON.parse(Deco(localStorage.getItem('user')));

    if (user.role == "ADMIN") {
      routes.push({
        path: "/baocaochung",
        name: "Báo cáo tháng này",
        icon: "nc-icon nc-bank",
        component: DashboardX,
        layout: "",
      })

      routes.push({
        path: "/taichinhcuahang",
        name: "Tài chính cửa hàng",
        icon: "nc-icon nc-badge",
        component: TaiChinh,
        layout: "",
      })

      routes.push({
        path: "/taichinhben",
        name: "Tài chính của Ben",
        icon: "nc-icon nc-badge",
        component: TaiChinh_Ben,
        layout: "",
      })

      routes.push({
        path: "/taichinhdet",
        name: "Tài chính của Đẹt",
        icon: "nc-icon nc-badge",
        component: TaiChinh_Det,
        layout: "",
      })

      routes.push({
        path: "/nhaphang",
        name: "Nhập hàng",
        icon: "nc-icon nc-badge",
        component: NhapHang,
        layout: "",
      })

      routes.push({
        path: "/tienban",
        name: "Tiền bán",
        icon: "nc-icon nc-badge",
        component: TienBanHang,
        layout: "",
      })

      routes.push({
        path: "/nhanvien",
        name: "Quản lý nhân viên",
        icon: "nc-icon nc-badge",
        component: NhanVien,
        layout: "",
      })
    }

    if (user.role == "STAFF" || user.role == "ADMIN") {
      routes.push({
        path: "/baocaongay",
        name: "Báo cáo ngày",
        icon: "nc-icon nc-box",
        component: Invoice,
        layout: "",
      })

      routes.push({
        path: "/donhangghtk",
        name: "Đơn hàng GHTK",
        icon: "nc-icon nc-box",
        component: DonHang_GHTK,
        layout: "",
      })

      routes.push({
        path: "/donhangtmdt",
        name: "Đơn hàng TMDT",
        icon: "nc-icon nc-box",
        component: DonHang_TMDT,
        layout: "",
      })

      routes.push({
        path: "/nvchi",
        name: "Phiếu chi",
        icon: "nc-icon nc-box",
        component: NhanVienChi,
        layout: "",
      })

      routes.push({
        path: "/phivanchuyen",
        name: "Phí vận chuyển",
        icon: "nc-icon nc-box",
        component: PhiVanChuyen,
        layout: "",
      })

      routes.push({
        path: "/inbanggia",
        name: "In bảng giá",
        icon: "nc-icon nc-badge",
        component: SanPham,
        layout: "",
      })
    }

    if (user.role == "SELLER" || user.role == "ADMIN") {
      routes.push({
        path: "/price",
        name: "Danh sách sản phẩm",
        icon: "nc-icon nc-badge",
        component: BangGiaSi,
        layout: "",
      })

      // routes.push({
      //   path: "/cart",
      //   name: "Giỏ hàng",
      //   icon: "nc-icon nc-badge",
      //   component: GioHangGiaSi,
      //   layout: "",
      // })

      routes.push({
        path: "/warranty",
        name: "Chính sách bảo hành",
        icon: "nc-icon nc-badge",
        component: ChinhSachBaoHanh,
        layout: "",
      })
    }

  }

  logged() {
    try {
      const user = JSON.parse(Deco(localStorage.getItem('user')));
      if (user == '') {
        window.location = "/login"
        return
      }

      var dateNow = new Date();
      var decodedToken = parseJwt(user.token)

      if (decodedToken.exp < dateNow.getTime() / 1000) {
        window.location = "/login"
        return
      }

    } catch (error) {
      window.location = "/login"
      return
    }

    function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    };
  }

  getTool() {
    const queryPath = window.location.pathname;
    return queryPath.replace(/admin/gi, '').replace(/[^a-zA-Z0-9 ]/g, '')
  }

  getBrand(arr) {
    let brandName = ''
    arr.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName
  }


  render() {
    const { newRoutes } = this.state
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={newRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar
            monthSelected={this.state.monthSelected}
            yearSelected={this.state.yearSelected}
            onChangeMonth={(e) => {
              this.setState({ monthSelected: e })
              this.monthYearChange.current.reloadData(e, this.state.yearSelected)
            }}
            onChangeYear={(e) => {
              this.setState({ yearSelected: e })
              this.monthYearChange.current.reloadData(this.state.monthSelected, e)
            }}

            title={this.getBrand(newRoutes)} {...this.props} />

          <Switch>
            {newRoutes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={() =>
                    <prop.component
                      ref={this.monthYearChange}
                      monthSelected={this.state.monthSelected}
                      yearSelected={this.state.yearSelected} />
                  }

                  key={key}
                />
              );
            })}
          </Switch>
          {/* <Footer fluid /> */}
        </div>
      </div>
    );
  }
}

export default Dashboard;
