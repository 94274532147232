import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";

class NhapHangSerive {

  getLichSuChiTien() {
    return axios.get(API_URL + `/taichinh/lichsuchitien`, { headers: authHeader() });
  }

  postLichSuChiTien(thangNam, cd, soTien, noiDung) {
    var config = {
      method: 'post',
      url: API_URL + '/taichinh/lichsuchitien',
      headers: authHeader(),
      data: { date: thangNam, cd: cd, amount: soTien, desc: noiDung }
    };

    return axios(config)
  }

  getKetToanThang() {
    return axios.get(API_URL + `/taichinh/kettoanthang`, { headers: authHeader() });
  }

  getKetToanDet_ThangNay(thangNam) {
    return axios.get(API_URL + `/taichinh/kettoandet/${thangNam}`, { headers: authHeader() });
  }

  getKetToanDet() {
    return axios.get(API_URL + `/taichinh/kettoandet`, { headers: authHeader() });
  }

  getKetToanBen_ThangNay(thangNam) {
    return axios.get(API_URL + `/taichinh/kettoanben/${thangNam}`, { headers: authHeader() });
  }

  getKetToanBen() {
    return axios.get(API_URL + `/taichinh/kettoanben`, { headers: authHeader() });
  }

  getKhoanVay() {
    return axios.get(API_URL + `/khoanvay`, { headers: authHeader() });
  }

}

export default new NhapHangSerive();
