import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";

class NhapHangSerive {

  getTienBan(month, year) {
    return axios.get(API_URL + `/tienbanhang/${month}.${year}`, { headers: authHeader() });
  }

  postTienBan(soTien, noiDung, dateCreate) {
    var config = {
      method: 'post',
      url: API_URL + '/tienbanhang',
      headers: authHeader(),
      data: { sotien: soTien, noidung: noiDung, date: dateCreate }
    };

    return axios(config)
  }

  deleteTienBan(id) {
    var config = {
      method: 'delete',
      url: API_URL + '/tienbanhang/' + id,
      headers: authHeader()
    };

    return axios(config)
  }
}

export default new NhapHangSerive();
