import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Row,
    CardHeader,
    CardTitle
} from "reactstrap";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

class Product extends React.Component {
    componentRef = null;

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <div className="content">
                <Row>
                    <Col>
                        <Card className="chinhsachbaohanh">
                            <CardHeader className="d-flex flex-row">
                                <CardTitle className="col-8 justify-content-start" tag="h5">Chính sách bảo hành</CardTitle>
                            </CardHeader>

                            <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                <Table className="table-hover " style={{ marginTop: "10px", lineHeight: "15px" }}>
                                    {/* <thead className="text-primary">
                                        <tr>
                                            <th>Tên sản phẩm</th>
                                            <th className='text-center'>Sẵn hàng</th>
                                            <th className='text-center'>Giá</th>
                                            <th className='text-center'>Bảo hành</th>
                                            <th className='text-center'>Thêm</th>
                                        </tr>
                                    </thead> */}
                                    <tbody  >
                                        <tr >
                                            <td className="table-device-mobile text-center" style={{ fontSize: "18px", lineHeight: 1.6, fontWeight: "bold", paddingRight: "30px", paddingLeft: "30px" }}>Chú ý</td>
                                            <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8 }}>
                                                - Các sản phẩm cung cấp cho quý đại lý, MiHome Buôn Ma Thuột cam kết 100% hàng mới, nguyên seal, như thông tin mô tả.<br />
                                                - Đại lý vui lòng cung cấp video nhận hàng, khui hàng trong các trường hợp nhận thiếu, sản phẩm hư hại, có dấu hiệu bóc mở.<br />
                                                - Khi gặp các vấn đề liên quan đến các nhà vận chuyển, thiên tai, dịch bệnh, hàng về chậm, giao hàng lâu. MiHome Buôn Ma Thuột mong đại lý thông cảm<br />
                                            </td>
                                        </tr>

                                        <tr >
                                            <td className="table-device-mobile text-center" style={{ fontSize: "18px", lineHeight: 1.6, fontWeight: "bold", paddingRight: "30px", paddingLeft: "30px" }}>Thời gian bảo hành</td>
                                            <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8 }}>
                                                - Các sản phẩm NẾU ĐƯỢC BẢO HÀNH từ hãng sẽ có thời gian bảo hành khác nhau, thời điểm tính bảo hành cho đại lý bắt đầu từ ngày chốt đơn và thanh toán với MiHome Buôn Ma Thuột.<br />
                                                - MiHome Buôn Ma Thuột sau khi tiếp nhận bảo hành cho đại lý sẽ phản hồi ngay với hãng, thời gian xử lý phụ thuộc vào hãng.<br />
                                            </td>
                                        </tr>

                                        <tr >
                                            <td className="table-device-mobile text-center" style={{ fontSize: "18px", lineHeight: 1.6, fontWeight: "bold", paddingRight: "30px", paddingLeft: "30px" }}>Điều kiện bảo hành</td>
                                            <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8 }}>
                                                - Các lỗi liên quan đến bộ điền khiển trung tâm Mainboard (tất cả các thành phần như wifi, bluetooth, chip xử lý ...).<br />
                                                - Sản phẩm còn nguyên hình dạng, kết cấu kỹ thuật như khi mua, không có dấu hiệu tháo lắp, thay thế linh kiện. Không bị lỗi về hình thức (trầy, xước, móp, méo, ố vàng, vỡ ...).<br />
                                                - Thực hiện đầy đủ quy trình báo lỗi theo quy chuẩn để hãng xem xét và hỗ trợ bảo hành.<br />
                                                - Chỉ bảo hành thân máy, không bảo hành các phụ kiện đi kèm.<br />
                                            </td>
                                        </tr>

                                        <tr >
                                            <td className="table-device-mobile text-center" style={{ fontSize: "18px", lineHeight: 1.6, fontWeight: "bold", paddingRight: "30px", paddingLeft: "30px" }}>Không bảo hành nếu</td>
                                            <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8 }}>
                                                - Sản phẩm bị hư hỏng do người dùng sử dụng không đúng cách, không đúng với thông số quy định của nhà sản xuất.<br />
                                                - Sản phẩm có các dấu hiệu tác động vật lý, không còn nguyên hình dạng, kết cấu kỹ thuật như khi mua.<br />
                                                - Các lỗi liên quan đến LDS, bánh xe, cản trước (robot hút bụi).<br />
                                                - Khách hàng tự ý tháo lắp, sửa chữa phần cứng, cập nhập phần mềm.<br />
                                                - Các lỗi liên quan đến phần mềm, firmware.<br />
                                                - Sản phẩm có IMEI không trùng khớp với thân máy, hoặc có dấu hiệu tẩy xóa, sửa chữa, biến dạng.<br />
                                                - Các phụ kiện đi kèm gặp lỗi (dây nguồn, dây sạc, dock sạc, điều khiển, các đầy hút bụi rời, khay nước, khay giẻ, điều khiển, lòng nồi cơm ...)<br />
                                                - Các phụ kiện hao mòn tự nhiên do tiếp xúc (vải, lớp da, pin, dẻ lau, tấm lọc, chổi, bánh xe, vỏ máy, tấm tiếp xúc máy massage, thảm máy chạy, mặt lưới ghế, màn loa ...)<br />
                                                - Các thiết bị thu phát sóng bluetooth (bao gồm usb receiver và các kết nối tới bàn phím, chuột), các thiết bị phát sóng ăng-ten, ...<br />
                                                - Sản phẩm không tương thích hoặc hư hỏng xuất phát từ việc sử dụng kết hợp giữa các thiết bị, chất hóa học, phần mềm không do hãng chỉ định.<br />
                                                - Sản phẩm bị hỏng hóc do các nguyên nhân từ thời tiết (bão lũ, sấm sét, động đất, hỏa hoạn, nóng ẩm ...).<br />
                                                - Sản phẩm bị hỏng hóc có dấu hiệu do côn trùng hoặc động vật gây nên.<br />
                                                - Sản phẩm không thể truy cập được các dịch vụ do mạng hoặc những thay đổi về cập nhập, chính sách của hãng.<br />
                                            </td>
                                        </tr>

                                        <tr >
                                            <td className="table-device-mobile text-center" style={{ fontSize: "18px", lineHeight: 1.6, fontWeight: "bold", paddingRight: "30px", paddingLeft: "30px" }}>Quy trình hỗ trợ bảo hành</td>
                                            <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8 }}>
                                                <tr>
                                                    <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8, borderTop: "none" }}>
                                                        (*) Quy trình báo lỗi tiêu chuẩn.<br />
                                                        - Quay lại clip thể hiện rxo IMEI (Serial) của sản phẩm trùng với vỏ hộp (nếu hộp có IMEI).<br />
                                                        - Tái hiện lại lỗi phát sinh trong quá trình chạy (nên làm chung trong 1 clip không cắt ghép). Trong clip có thể mô tả rõ ràng tình trạng ngoại quan máy là bình thường (không bị vào nước, chập cháy, biến dạng, móp méo vv...).<br />
                                                        - Có thể mô tả ngắn về quá trình sử dụng của khách hàng là hoàn toàn bình thường, đúng theo hướng dẫn của NSX.<br />
                                                        - Nếu máy có chế độ Reset, bổ sung thêm tính năng sau khi reset máy vẫn tồn tại lỗi.<br />
                                                        - Tuyệt đối không tháo máy với bất kể lý do gì, dù chỉ là xem bên trong.<br />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="table-device-mobile" style={{ fontSize: "16px", lineHeight: 1.8 }}>
                                                        (*) Quy trình hỗ trợ bảo hành.<br />
                                                        - MiHome Buôn Ma Thuột hỗ trợ các quy trình bảo hành, hướng dẫn cho các đại lý. Đại lý làm việc với khách hàng. MiHome Buôn Ma Thuột không làm việc với khách hàng của đại lý.<br />
                                                        - Sản phẩm đại lý được bảo hành. Đại lý thực hiện quy trình báo lỗi tiêu chuẩn sau đó báo lại MiHome Buôn Ma Thuột để tiến hành bảo hành theo quy định của hãng.<br />
                                                        - Sản phẩm đại lý thuộc diện không được bảo hành. Đại lý thực hiện quy trình báo lỗi tiêu chuẩn sau đó báo lại MiHome Buôn Ma Thuột để được hỗ trợ bảo hành, sữa chữa tính phí.<br />
                                                        - Sản phẩm gửi về bao gồm: Tên đại lý, mô tả sơ bộ máy, phần máy bảo hành, các phụ kiện đi kèm máy, dây sạc, dock sạc ...<br />
                                                    </td>
                                                </tr>

                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default Product;