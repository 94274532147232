import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    Alert
} from "reactstrap";

import InvoiceSerivce from "../services/invoices.service"
import BaoCaoService from "../services/baocao.service"

import Select from 'react-select';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders(props) {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)

    const [showTongChi, setShowTongChi] = useState(false)
    const [tongChi, setTongChi] = useState(null)


    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState([])
    const [search, setSearch] = useState(null)
    const [pageOrder, setPageOrder] = useState(0)
    const [choxuly, setChoXuLy] = useState(0)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState(null)

    const [date, setDate] = useState(moment(new Date()).format("DD/MM/YYYY"))
    const [calendar, setCalendar] = useState(new Date());
    const [activeCalendar, setActiveCalendar] = useState(false)

    const [tienMat, setTienMat] = useState(0)
    const [chuyenKhoan, setChuyenKhoan] = useState(0)
    const [quetThe, setQuetThe] = useState(0)

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function toDetailView(val) {
        setDetails(val)
        openModal()
    }

    useEffect(() => {
        getData(moment(date, "DD/MM/YYYY").toDate())
    }, [])


    function getData(dateX) {
        InvoiceSerivce.getInvoices_BanLe(dateX.toISOString()).then((result) => {
            setOrders(result.data)
            setLoading(false)

            var tm = 0
            var ck = 0
            var qt = 0

            result.data.forEach(ele => {
                if (ele.usingCod == false) {
                    try {
                        ele.payments.forEach(pay => {
                            if (pay.method == "Cash") {
                                tm += pay.amount
                            } else if (pay.method == "Transfer") {
                                ck += pay.amount
                            } else if (pay.method == "Card") {
                                qt += pay.amount
                            }
                        })
                    } catch (error) {
                    }
                }
            });

            setTienMat(tm)
            setChuyenKhoan(ck)
            setQuetThe(qt)
        })

        InvoiceSerivce.getInvoices_TMDT(`${props.monthSelected}.${props.yearSelected}`).then((result) => {
            var choxuly = 0
            result.data.forEach(ele => {
                if (ele.invoiceDelivery.status == 1) choxuly += 1
            });

            setChoXuLy(choxuly)
        })

        BaoCaoService.getNhanVienChi_day(dateX.toISOString()).then((result) => {
            // Thống kê nhân viên chi
            var thongke = []

            setShowTongChi(false)

            result.data.forEach(ele => {
                setShowTongChi(true)

                var isExist = false
                var index = 0
                var sotien = 0

                for (var ik = 0; ik < thongke.length; ik++) {
                    if (thongke[ik].nguoichi == ele.nguoichi) {
                        index = ik
                        sotien = parseInt(thongke[ik].sotien)
                        isExist = true
                    }
                }

                if (isExist) {
                    sotien += parseInt(ele.sotien);
                    thongke[index] = {
                        nguoichi: ele.nguoichi,
                        sotien: sotien
                    }
                } else {
                    thongke.push({
                        nguoichi: ele.nguoichi,
                        sotien: parseInt(ele.sotien)
                    })
                }
            });

            var thongke_NhanVienChi = thongke.map((data, key) => {
                return (
                    <tr style={{ cursor: "pointer" }} key={key}>
                        <td>{data.nguoichi}:</td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.sotien)}</td>
                    </tr>
                )
            })

            setTongChi(thongke_NhanVienChi)
        })
    }


    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }


    function selectACalendar(value) {
        const date = moment(value).toDate()
        setCalendar(date)

        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        setDate(moment(newDate).format("DD/MM/YYYY"))
        setActiveCalendar(false)

        getData(date)
    }

    function checkTransaction(total) {
        InvoiceSerivce.getTransactions(total)
            .then((result) => {
                if (result.data.received) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Đã nhận được tiền',
                        showConfirmButton: true,
                        timer: 5000
                    }).then(res => {
                        //setModalShow(true)
                        //window.location.reload()
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Chưa nhận được tiền',
                        showConfirmButton: true,
                        timer: 5000
                    }).then(res => {
                        //setModalShow(true)
                        //window.location.reload()
                    })
                }
            }).catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Quá nhiều yêu cầu truy cập. Thử lại sau 15 giây',
                    showConfirmButton: true,
                    timer: 5000
                }).then(res => {
                    //setModalShow(true)
                    //window.location.reload()
                })
            })
    }

    function loadFillData() {
        return orders.filter((data) => {

            // Phân tích phương thức thanh toán
            var paymentMethod = ""
            try {
                if (data.payments[0].method == "Cash") paymentMethod = "Tiền mặt"
                if (data.payments[0].method == "Transfer") paymentMethod = "Mã QR"
                if (data.payments[0].method == "Card") paymentMethod = "Quẹt thẻ"
            } catch (error) {
            }
            data.paymentMethod = paymentMethod

            // Đổ màu cho row
            data.color = ""
            if (data.status == 2) data.color = "table-danger"
            if (data.status == 1) data.color = "table-success"

            if (search == null) {
                return data
            } else if (data.customer.toLowerCase().includes(search.toLowerCase())) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr className={data.color} style={{ cursor: "pointer" }} key={key} onClick={() => toDetailView(data)}>
                    <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{moment(data.date).format('HH:mm')}</td>
                    <td className="table-device-mobile">{data.code}</td>
                    <td className="table-device-mobile">{data.soldByName}</td>
                    <td className="table-device-mobile">{data.customer}</td>
                    <td className="table-device-mobile">{data.statusValue}</td>
                    <td className="table-device-mobile">{data.paymentMethod}</td>
                    <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.total)}</td>
                    {/* <Button color="success" onClick={() => checkTransaction(data.total)}>Kiểm tra</Button> */}
                </tr>
            )
        })
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col md="3">
                            <Card className="tongket">
                                <CardHeader>
                                    <CardTitle tag="h5">Chọn ngày</CardTitle>
                                </CardHeader>

                                <CardBody>
                                    <Input
                                        className="d-none d-sm-block"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                            if (activeCalendar) {
                                                setActiveCalendar(false)
                                            } else {
                                                setActiveCalendar(true)
                                            }
                                        }}
                                        //onChange={(e) => setDate(e.target.value)}
                                        value={date}
                                        id="expired"
                                    />

                                    <div style={{ marginTop: "10px" }}>
                                        {
                                            activeCalendar
                                                ?
                                                <>
                                                    <Calendar
                                                        style={{ border: "0px solid #a0a096" }}
                                                        onChange={(e) => { selectACalendar(e) }}
                                                        value={calendar}
                                                    />
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </CardBody>
                            </Card>

                            <Card className="tongket">
                                <CardHeader>
                                    <CardTitle tag="h5">Tổng kết tiền</CardTitle>
                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <tbody>
                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Tiền mặt:</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tienMat)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Quẹt thẻ:</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(quetThe)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Mã QR:</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>


                                            {/* <tr style={{ cursor: "pointer" }} >
                                                <td>Chưa thu TMDT:</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Chưa thu GHTK COD:</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>

                            {
                                showTongChi
                                    ?
                                    <Card className="thongke_NhanVienChi">
                                        <CardHeader>
                                            <CardTitle tag="h5">Phiếu chi</CardTitle>
                                        </CardHeader>
                                        <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                            <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                                <tbody>
                                                    {tongChi}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                    : null
                            }

                            {
                                choxuly > 0
                                    ?
                                    <Alert color="danger">
                                        Có {choxuly} đơn hàng TMDT chưa giao cho Shipper
                                    </Alert>
                                    :
                                    null
                            }


                            {/* <Card className="tongket">
                                <CardHeader>
                                    <CardTitle tag="h5">Tổng đơn hàng</CardTitle>
                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Kênh bán</th>
                                                <th>Đơn</th>
                                                <th className="table-device-mobile">Tổng tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Bán lẻ:</td>
                                                <td>1</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tienMat)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>GHTK CK:</td>
                                                <td>1</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>GHTK COD:</td>
                                                <td>1</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Lazada:</td>
                                                <td>1</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Shopee:</td>
                                                <td>1</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>

                                            <tr style={{ cursor: "pointer" }} >
                                                <td>Sendo:</td>
                                                <td>1</td>
                                                <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card> */}
                        </Col>

                        <Col md="9">
                            <Card className="nhanvienchi">
                                <CardHeader className="function justify-content-between">
                                    <Row>
                                        <Col md="9">
                                            <CardTitle tag="h5">Đơn hàng bán lẻ</CardTitle>
                                        </Col>

                                        <Col md="3">
                                            <Input
                                                style={{ height: "38px", marginTop: "7px" }}
                                                value={search}
                                                onChange={(e) => searchSpace(e)}
                                                placeholder="Tìm kiếm" />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        {loading
                                            ?
                                            <span class="spinner-border spinner-border-sm"></span>
                                            :
                                            <>
                                                <thead className="text-primary">
                                                    <tr>
                                                        <th>Thời gian</th>
                                                        <th>Mã HD</th>
                                                        <th>Nhân viên bán</th>
                                                        <th>Khách hàng</th>
                                                        <th>Trạng thái</th>
                                                        <th className="table-device-mobile">PTTT</th>
                                                        <th className="table-device-mobile">Số tiền</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {loadFillData()}
                                                </tbody>
                                            </>
                                        }
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    <Modal size="lg" style={{ maxWidth: '900px', width: '100%' }} className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Chi tiết đơn hàng</h5>
                                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {details ?
                                    <Form>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Mã hóa đơn</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.code} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Tên khách hàng</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.customer} />
                                            </div>
                                        </div>

                                        <br />
                                        <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Tên sản phẩm</th>
                                                    <th>Số lượng</th>
                                                    <th>Giá tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {details.invoiceDetails.map((dataX, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{dataX.productName}</td>
                                                            <td>{dataX.quantity}</td>
                                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(dataX.price)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Form>
                                    :
                                    null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </Modal>

                </>
            }
        </div>
    )
}

export default Orders