import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    InputGroup,
    FormGroup,
    Label
} from "reactstrap";

import TaiChinhService from "../services/taichinh.service"

import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders(props) {

    const [ketToan, setKetToan] = useState([])
    const [daBan, setDaBan] = useState(0)
    const [daChi, setDaChi] = useState(0)
    const [daDua, setDaDua] = useState(0)
    const [tongNo, setTongNo] = useState(0)

    const [data, setData] = useState(null)

    const [lichSuChiTien_thangNam, setLichSuChiTien_thangNam] = useState("")
    const [lichSuChiTien_cd, setLichSuChiTien_cd] = useState("+")
    const [lichSuChiTien_soTien, setLichSuChiTien_soTien] = useState("")
    const [lichSuChiTien_noidung, setLichSuChiTien_noidung] = useState("")

    const [lichSuChiTien_total, setLichSuChiTien_total] = useState(0)

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    //Form load
    useEffect(() => {
        loadData_TaiChinhDet()
    }, [])

    // Lịch sử chi tiền
    function loadData_TaiChinhDet() {
        TaiChinhService.getKetToanDet().then((result) => {
            setKetToan(result.data.data)
            setTongNo(result.data.tongno)

            result.data.data.forEach(ele => {
                if (ele.date == moment(`${props.monthSelected}-${props.yearSelected}`, 'MM-YYYY').format('MM/yyyy')) {
                    setDaBan(ele.daban)
                    setDaChi(ele.dachi)
                    setDaDua(ele.dadua)
                }
            });
        })

        TaiChinhService.getKetToanDet_ThangNay(`${props.monthSelected}.${props.yearSelected}`).then((result) => {
            console.log(result)
            setData(result.data)
        })
    }

    function loadFillData_KetToanThang() {
        return ketToan.map((data, key) => {
            return (
                <tr key={key} style={{ borderBottom: "2px solid black", borderTop: "2px solid black" }} >
                    <td style={{ fontWeight: 'bold' }}>{data.date}</td>

                    <tr style={{ borderTop: "0px hidden" }}>
                        <td>Đã bán: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daban)}</td>
                    </tr>
                    <tr>
                        <td>Đã chi: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.dachi)}</td>
                    </tr>
                    <tr>
                        <td>Đã đưa Ben: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.dadua)}</td>
                    </tr>
                    <tr >
                        <td>Đang nợ Ben: </td>
                        <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.dangno)}</td>
                    </tr>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col md="3">
                            <Card className="thongke">
                                <CardHeader>
                                    <CardTitle tag="h5">Kết toán {moment(`${props.monthSelected}-${props.yearSelected}`, 'MM-YYYY').format('MM/yyyy')}</CardTitle>
                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <tbody>
                                            <tr>
                                                <td>Bán lẻ tại cửa hàng: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daban_Cash + data.daban_Card) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Bán TMDT & GHTK: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daBan_TMDT) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Đã chi: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daChi) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Đã đưa Ben: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daDua) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Còn thiếu Ben: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.conLai) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3" md="3" xs="12">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category">Tổng nợ Ben</p>
                                                <CardTitle tag="p">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tongNo)} </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3" md="3" xs="12">
                            <Card className="taichinh">
                                <CardHeader className="function justify-content-between">
                                    <CardTitle tag="h5">Lịch sử kết toán</CardTitle>
                                </CardHeader>

                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Tháng</th>
                                                <th>Thông tin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadFillData_KetToanThang()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Thêm lịch sử chi tiền</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <FormGroup>
                                    <Label for="exampleEmail">Tháng/Năm</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="exampleEmail"
                                        value={lichSuChiTien_thangNam}
                                        onChange={(e) => setLichSuChiTien_thangNam(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="examplePassword">Số tiền</Label>
                                    <Input
                                        type="text"
                                        name="text"
                                        id="examplePassword"
                                        value={lichSuChiTien_soTien}
                                        onChange={(e) => setLichSuChiTien_soTien(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fullname">Nội dung</Label>
                                    <Input
                                        type="fullname"
                                        name="fullname"
                                        id="fullname"
                                        value={lichSuChiTien_noidung}
                                        onChange={(e) => setLichSuChiTien_noidung(e.target.value)}
                                    />
                                </FormGroup>
                                {/* <FormGroup>
                                    <Label for="examplePassword">Role</Label>
                                    <Select
                                        placeholder={role}
                                        value={role}
                                        onChange={(e) =>
                                            setRole(e.value)
                                        }
                                        options={[
                                            { value: 'ADMIN', label: 'ADMIN' },
                                            { value: 'MANAGER', label: 'MANAGER' },
                                            { value: 'STAFF', label: 'STAFF' }
                                        ]}
                                    />
                                </FormGroup> */}
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Hủy</button>
                                <button type="button" className="btn btn-primary" onClick={create_LichSuChiTien}>Thêm</button> */}
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </div >
    )
}

export default Orders