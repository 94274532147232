import axios from "axios";
import Enco from "security/enco"

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";


class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "/login", {
        username,
        password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem("user", Enco(JSON.stringify(response.data)));
          localStorage.setItem("month", (new Date()).getMonth() + 1);
          localStorage.setItem("year", (new Date()).getFullYear());
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    window.location = "/login"
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }

}

export default new AuthService();
