import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    CardFooter,
    Container,
    Label,
    FormGroup
} from "reactstrap";

import ReactToPrint from "react-to-print";

import GiaSiService from "../services/giasi.service"

import Select from 'react-select';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

import { FiCheckCircle, FiEdit3, FiXCircle, FiX, FiChevronsRight, FiPlus, FiCheck, FiPrinter } from "react-icons/fi";
import { BsCartPlus } from "react-icons/bs";


import Deco from "../security/deco"
import BangGia from '../components/Childrens/BangGiaFunc'
import logoXiaomi from "xiaomiLogo.png";

import BangGia7x5 from 'components/Childrens/BangGiaPreview/BangGia7x5';
import BangGia7x10 from 'components/Childrens/BangGiaPreview/BangGia7x10';
import BangGia7x8 from 'components/Childrens/BangGiaPreview/BangGia7x8';
import BangGia4x8 from 'components/Childrens/BangGiaPreview/BangGia4x8';

import { ComponentToPrint } from 'components/Childrens/HDSDPreView/HDSD10x15'

class Product extends React.Component {
    componentRef = null;

    constructor(props) {
        super(props);

        this.state = {
            //Danh sách sản phẩm
            products: [],

            // Data sửa sản phẩm
            editProduct_idProduct: null,
            editProduct_name: null,
            editProduct_1: null,
            editProduct_1_pcs: null,
            editProduct_2: null,
            editProduct_2_pcs: null,
            editProduct_warranty: 0,



            search: null,
            showLoadSearch: false,

            modalIsOpen: false,
            detailProduct: null,

            lstSelected: [],
            page: 'select',
            showLoadTruoc: false,
            showLoadSau: false,
            currentItem: 0,

            modalIsOpen_HDSD: false,
            details_HDSD: null,
            text: "old boring text",

            addToCart_SelectProduct: null,
            addToCart_Quantity: 1,

            role: "SELLER",
            loading: true
        }
    }

    componentDidMount() {
        const user = JSON.parse(Deco(localStorage.getItem('user')));
        this.setState({ role: user.role })

        GiaSiService.getPrices(this.state.currentItem).then((result) => {
            this.setState({ products: result.data.data })
            this.setState({ loading: false })
        })
    }


    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.setState({ showLoadSearch: true })
        }
    }

    addToSelected(val) {
        var tmp = this.state.lstSelected

        if (tmp.length > 14) {
            console.log("Thêm tối đa 15 sản phẩm")
        } else {
            tmp.push(val)
            this.setState({ lstSelected: tmp })
        }
    }

    deleteToSelected(val) {
        var tmp = []
        this.state.lstSelected.forEach(ele => {
            if (ele.id != val.id) {
                tmp.push(ele)
            }
        });

        this.setState({ lstSelected: tmp })
    }





    openModal_editProduct(val) {

        if (val.data) {
            this.setState({
                editProduct_idProduct: val.id,
                editProduct_name: val.fullName,
                editProduct_1: val.data.price_1,
                editProduct_1_pcs: val.data.price_1_pcs,
                editProduct_2: val.data.price_2,
                editProduct_2_pcs: val.data.price_2_pcs,
                editProduct_warranty: val.data.warranty,
            })
        } else {
            this.setState({
                editProduct_idProduct: val.id,
                editProduct_name: val.fullName,
                editProduct_1: val.basePrice,
                editProduct_1_pcs: 1,
                editProduct_2: 0,
                editProduct_2_pcs: 0,
                editProduct_warranty: "Không",
            })
        }


        // Mở dialog
        this.setState({ modalIsOpen: true });
    }

    closeModal_editProduct() {
        this.setState({ modalIsOpen: false });
    }

    updateProduct() {
        var data = {
            name: this.state.editProduct_name,
            price_1: this.state.editProduct_1,
            price_1_pcs: this.state.editProduct_1_pcs,
            price_2: this.state.editProduct_2,
            price_2_pcs: this.state.editProduct_2_pcs,
            warranty: this.state.editProduct_warranty
        }

        GiaSiService.postPrice(this.state.editProduct_idProduct, data).then((result) => {

            console.log(result.data)

            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                timer: 600
            })

            var lstProducts = this.state.products
            for (let index = 0; index < lstProducts.length; index++) {
                const element = lstProducts[index];
                if (element.id == this.state.editProduct_idProduct) {

                    console.log()
                    var replaceData = lstProducts[index]
                    replaceData.fullName = this.state.editProduct_name
                    var x = {
                        price_1: this.state.editProduct_1,
                        price_1_pcs: this.state.editProduct_1_pcs,
                        price_2: this.state.editProduct_2,
                        price_2_pcs: this.state.editProduct_2_pcs,
                        warranty: this.state.editProduct_warranty
                    }
                    replaceData.data = x

                    this.setState({ products: lstProducts })
                    break;
                }
            }


        })
    }






    openModal_HDSD() {
        this.setState({ modalIsOpen_HDSD: true });
    }

    closeModal_HDSD() {
        this.setState({ modalIsOpen_HDSD: false });
    }


    toDetailView_HDSD(val) {
        this.setState({ addToCart_SelectProduct: val })
        this.openModal_HDSD()
    }

    updateDetails() {
        console.log(this.state.addToCart_SelectProduct)

        var data = {
            idProduct: this.state.addToCart_SelectProduct.id,
            quantity: this.state.addToCart_Quantity,
            name: this.state.addToCart_SelectProduct.fullName
        }


        if (this.state.addToCart_SelectProduct.data) {
            data.price_1 = this.state.addToCart_SelectProduct.data.price_1
            data.price_1_pcs = this.state.addToCart_SelectProduct.data.price_1_pcs
            data.price_2 = this.state.addToCart_SelectProduct.data.price_2
            data.price_2_pcs = this.state.addToCart_SelectProduct.data.price_2_pcs
            data.warranty = this.state.addToCart_SelectProduct.data.warranty
        }

        console.log(data)

        GiaSiService.postCart(data).then((result) => {
            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                timer: 600
            })
        })
    }



    render() {
        return (
            <div className="content">
                <Row>
                    <Col>
                        <Card className="nhanvienchi">
                            <CardHeader className="d-flex flex-row">
                                <CardTitle className="col-8 justify-content-start" tag="h5">Danh sách sản phẩm</CardTitle>
                                {/* <Input
                                    className="justify-content-end"
                                    style={{ height: "35px", marginTop: "7px" }}
                                    value={this.state.search}
                                    onKeyDown={(e) => this.handleKeyDown(e)}
                                    placeholder="Tìm kiếm" /> */}
                            </CardHeader>

                            {
                                this.state.loading
                                    ?
                                    <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <div className='d-flex justify-content-center' style={{ height: "400px", fontSize: "40px" }}>
                                            <span className="spinner-border spinner-border-sm align-self-center" style={{ width: "80px", height: "80px" }}></span>
                                        </div>
                                    </CardBody>
                                    :
                                    <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                        <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Tên sản phẩm</th>
                                                    <th className='text-center'>Sẵn hàng</th>
                                                    <th className='text-center'>Mức giá 1 (SL tối tiểu / Giá)</th>
                                                    <th className='text-center'>Mức giá 2 (SL tối tiểu / Giá)</th>
                                                    <th className='text-center'>Bảo hành</th>

                                                    {
                                                        this.state.role == "ADMIN"
                                                            ? <th className='text-center'>Sửa</th>
                                                            : null
                                                    }

                                                    {/* <th className='text-center'>Thêm</th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.showProducts()}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                            }

                            <CardFooter>
                                <nav className="pagination justify-content-center ">
                                    <ul className="pagination">
                                        <Button
                                            style={{ marginRight: "10px" }}
                                            color="info"
                                            onClick={() => {
                                                var index = this.state.currentItem - 100
                                                if (index < 0) {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        title: 'Đây là trang đầu',
                                                        showConfirmButton: false,
                                                        timer: 1000
                                                    })
                                                    return
                                                }

                                                this.setState({ showLoadTruoc: true })
                                                this.setState({ currentItem: index })

                                                GiaSiService.getPrices(index).then((result) => {
                                                    this.setState({ products: result.data.data })
                                                    this.setState({ showLoadTruoc: false })
                                                })
                                            }}>
                                            {this.state.showLoadTruoc ? <span class="spinner-border spinner-border-sm"></span> : <> &laquo; Trang trước </>}
                                        </Button>
                                        <Button
                                            color="info"
                                            onClick={() => {
                                                this.setState({ showLoadSau: true })

                                                var index = this.state.currentItem + 100
                                                this.setState({ currentItem: index })

                                                GiaSiService.getPrices(index).then((result) => {
                                                    this.setState({ products: result.data.data })
                                                    this.setState({ showLoadSau: false })
                                                })
                                            }}>
                                            {this.state.showLoadSau ? <span class="spinner-border spinner-border-sm"></span> : <> Trang sau &raquo; </>}
                                        </Button>
                                    </ul>
                                </nav>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

                <Modal size="md" className="modal-dialog modal-dialog-centered"
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={() => this.closeModal()}
                >
                    <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                        <div className="modal-header">
                            <h5 className="modal-title">Chỉnh sửa sản phẩm</h5>
                            <button type="button" onClick={() => this.closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group row">
                                    <label for="quantity" className="col-sm-3 col-form-label">Tên</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="1"
                                            value={this.state.editProduct_name}
                                            onChange={(e) => this.setState({ editProduct_name: e.target.value })}
                                        ></input>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="quantity" className="col-sm-3 col-form-label">SL / Giá 1</label>
                                    <div className="col-sm-9">
                                        <Row>
                                            <Col className="col-sm-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={this.state.editProduct_1_pcs}
                                                    onChange={(e) => this.setState({ editProduct_1_pcs: e.target.value })}
                                                ></input>
                                            </Col>
                                            <Col className="col-sm-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={this.state.editProduct_1}
                                                    onChange={(e) => this.setState({ editProduct_1: e.target.value })}
                                                ></input>
                                            </Col>

                                        </Row>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="quantity" className="col-sm-3 col-form-label">SL / Giá 2</label>
                                    <div className="col-sm-9">
                                        <Row>
                                            <Col className="col-sm-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={this.state.editProduct_2_pcs}
                                                    onChange={(e) => this.setState({ editProduct_2_pcs: e.target.value })}
                                                ></input>
                                            </Col>

                                            <Col className="col-sm-6">
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={this.state.editProduct_2}
                                                    onChange={(e) => this.setState({ editProduct_2: e.target.value })}
                                                ></input>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label for="quantity" className="col-sm-3 col-form-label">Bảo hành</label>
                                    <Select
                                        className="col-sm-9"
                                        placeholder={this.state.editProduct_warranty}
                                        value={this.state.editProduct_warranty}
                                        onChange={(e) => this.setState({ editProduct_warranty: e.value })}
                                        options={[
                                            { value: 'Không', label: 'Không' },
                                            { value: '1 Tháng', label: '1 Tháng' },
                                            { value: '6 Tháng', label: '6 Tháng' },
                                            { value: '12 Tháng', label: '12 Tháng' },
                                            { value: '24 Tháng', label: '24 Tháng' }
                                        ]}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => {
                                this.closeModal_editProduct()
                                this.updateProduct()
                            }}>Thêm</button>

                            <button type="button" className="btn btn-danger" onClick={() => this.closeModal_editProduct()}>Hủy</button>
                        </div>
                    </div>
                </Modal>

                <Modal size="xs" className="modal-dialog modal-dialog-centered"
                    isOpen={this.state.modalIsOpen_HDSD}
                    onRequestClose={() => this.closeModal_HDSD()}
                >
                    <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                        <div className="modal-header">
                            <h5 className="modal-title">Thêm vào giỏ hàng</h5>
                            <button type="button" onClick={() => this.closeModal_HDSD()} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                this.state.addToCart_SelectProduct != null
                                    ?
                                    <form>
                                        <div class="form-group row">
                                            <label for="staticEmail" class="col-sm-3 col-form-label">Sản phẩm:</label>
                                            <div class="col-sm-9">
                                                <input type="text" readonly class="form-control-plaintext" id="staticEmail" value={this.state.addToCart_SelectProduct.fullName}></input>
                                            </div>
                                        </div>
                                        <div class="form-group row">
                                            <label for="quantity" class="col-sm-3 col-form-label">Số lượng:</label>
                                            <div class="col-sm-9">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="1"
                                                    value={this.state.addToCart_Quantity}
                                                    onChange={(e) => this.setState({ addToCart_Quantity: e.target.value })}
                                                ></input>
                                            </div>
                                        </div>
                                    </form>
                                    :
                                    null
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => {
                                this.closeModal_HDSD()
                                this.updateDetails()
                            }}>Thêm</button>

                            <button type="button" className="btn btn-danger" onClick={() => this.closeModal_HDSD()}>Hủy</button>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }


    showProducts() {
        return this.state.products.map((data, key) => {
            return (
                <tr className={data.color} key={key} >

                    <td className="table-device-mobile">{data.fullName}</td>

                    <td className='text-center'>{
                        data.stock > 0
                            ?
                            <FiCheck className="text-success text-center" style={{ marginLeft: "3px", fontSize: "25px" }} />
                            :
                            <FiX className="text-danger text-center" style={{ marginLeft: "3px", fontSize: "25px" }} />
                    }</td>

                    {
                        data.data
                            ?
                            <>
                                <td className='text-center'>{data.data.price_1_pcs} sp / {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.data.price_1)} </td>

                                {
                                    (data.data.price_2_pcs > 0 && data.data.price_2 > 0)
                                        ?
                                        <td className='text-center'>{data.data.price_2_pcs} sp / {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.data.price_2)} </td>
                                        :
                                        <td></td>
                                }

                                {
                                    (data.data.warranty == "Không" || data.data.warranty == null)
                                        ?
                                        <td></td>
                                        :
                                        <td className='text-center'>{data.data.warranty}</td>
                                }

                                {
                                    this.state.role == "ADMIN"
                                        ?
                                        <td className='text-center'>
                                            <FiEdit3 className="text-warning" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { this.openModal_editProduct(data) }} />
                                        </td>
                                        : null
                                }

                                {/* <td className='text-center'>
                                    <FiPlus className="text-success" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { this.toDetailView_HDSD(data) }} />
                                </td> */}
                            </>
                            :
                            <>
                                <td className='text-center'>1 sp / {new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.basePrice)}</td>
                                <td></td>
                                <td></td>
                                {
                                    this.state.role == "ADMIN"
                                        ?
                                        <td className='text-center'>
                                            <FiEdit3 className="text-warning" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { this.openModal_editProduct(data) }} />
                                        </td>
                                        : null
                                }
                                {/* <td className='text-center'>
                                    <FiPlus className="text-success" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { this.toDetailView_HDSD(data) }} />
                                </td> */}
                            </>
                    }


                </tr>
            )
        })
    };
}

export default Product;