import React from "react";
import {
  Navbar,
  Container,
  Input, Button, Modal, Col, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, Row
} from "reactstrap";

import { isMobile } from 'react-device-detect';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import AuthService from "services/auth.service";
import 'sweetalert2/src/sweetalert2.scss';
import Deco from "security/deco"
import moment from 'moment'

import Select from 'react-select'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      fullname: "",
      role: "",

      monthSelected: this.props.monthSelected,
      yearSelected: this.props.yearSelected,
    };

    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.sidebarToggle = React.createRef();
  }

  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent",
      });
    } else {
      this.setState({
        color: "dark",
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  getBrand() {
    return this.props.title;
  }

  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  }

  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark",
      });
    } else {
      this.setState({
        color: "transparent",
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
    this.getFullNameUser()
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  logout() {
    AuthService.logout()
  }

  getFullNameUser() {
    const user = JSON.parse(Deco(localStorage.getItem('user')));
    this.setState({ fullname: user.fullname })
    this.setState({ role: user.role })
  }


  render() {

    return (
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
            (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        {
          isMobile
            ?
            (this.state.role == "ADMIN" || this.state.role == "STAFF")
              ?
              <Container fluid >
                <div className="navbar-wrapper">
                  <div className="navbar-toggle">
                    <button
                      type="button"
                      ref={this.sidebarToggle}
                      className="navbar-toggler"
                      onClick={() => this.openSidebar()}
                    >
                      <span className="navbar-toggler-bar bar1" />
                      <span className="navbar-toggler-bar bar2" />
                      <span className="navbar-toggler-bar bar3" />
                    </button>
                  </div>
                  <NavbarBrand>{this.getBrand()} : {this.state.monthSelected}/{this.state.yearSelected}</NavbarBrand>
                </div>

                <NavbarToggler onClick={this.toggle}>
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                  <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>

                <Collapse
                  isOpen={this.state.isOpen}
                  navbar
                  className="justify-content-end"
                >
                  <Nav navbar>
                    <NavItem>
                      <Row xs="12">
                        <Col xs="6">
                          <Select
                            isSearchable={false}
                            value={{ value: this.state.monthSelected, label: "Tháng " + this.state.monthSelected }}
                            onChange={(e) => {
                              this.setState({ monthSelected: e.value })
                              localStorage.setItem("month", e.value);
                              this.props.onChangeMonth(e.value)
                              this.toggle()
                            }}
                            options={[
                              { value: 1, label: "Tháng 1" },
                              { value: 2, label: "Tháng 2" },
                              { value: 3, label: "Tháng 3" },
                              { value: 4, label: "Tháng 4" },
                              { value: 5, label: "Tháng 5" },
                              { value: 6, label: "Tháng 6" },
                              { value: 7, label: "Tháng 7" },
                              { value: 8, label: "Tháng 8" },
                              { value: 9, label: "Tháng 9" },
                              { value: 10, label: "Tháng 10" },
                              { value: 11, label: "Tháng 11" },
                              { value: 12, label: "Tháng 12" }
                            ]} />
                        </Col>
                        <Col xs="6">
                          <Select
                            isSearchable={false}
                            value={{ value: this.state.yearSelected, label: "Năm " + this.state.yearSelected }}
                            onChange={(e) => {
                              this.setState({ yearSelected: e.value })
                              localStorage.setItem("year", e.value);
                              this.props.onChangeYear(e.value)
                            }}
                            options={[
                              { value: 2020, label: "Năm 2020" },
                              { value: 2021, label: "Năm 2021" },
                              { value: 2022, label: "Năm 2022" },
                              { value: 2023, label: "Năm 2023" },
                              { value: 2024, label: "Năm 2024" },
                              { value: 2025, label: "Năm 2025" },
                            ]} />
                        </Col>
                      </Row>
                    </NavItem>
                  </Nav>

                  <Nav navbar>
                    <Button style={{ marginTop: "10px" }} color="danger" className="justify-content-end" onClick={() => this.logout()} >Đăng xuất</Button>
                  </Nav>
                </Collapse>
              </Container>
              :
              <Nav navbar>
                <Button style={{ marginTop: "10px" }} color="danger" className="justify-content-end" onClick={() => this.logout()} >Đăng xuất</Button>
              </Nav>
            :

            <Container fluid >
              <div style={{ width: '310px' }}>

                {(this.state.role == "ADMIN" || this.state.role == "STAFF")
                  ?
                  <>
                    <div style={{ width: '150px', float: "left", marginRight: "10px" }}>
                      <Select
                        value={{ value: this.state.monthSelected, label: "Tháng " + this.state.monthSelected }}
                        onChange={(e) => {
                          this.setState({ monthSelected: e.value })
                          localStorage.setItem("month", e.value);
                          this.props.onChangeMonth(e.value)
                        }}
                        options={[
                          { value: 1, label: "Tháng 1" },
                          { value: 2, label: "Tháng 2" },
                          { value: 3, label: "Tháng 3" },
                          { value: 4, label: "Tháng 4" },
                          { value: 5, label: "Tháng 5" },
                          { value: 6, label: "Tháng 6" },
                          { value: 7, label: "Tháng 7" },
                          { value: 8, label: "Tháng 8" },
                          { value: 9, label: "Tháng 9" },
                          { value: 10, label: "Tháng 10" },
                          { value: 11, label: "Tháng 11" },
                          { value: 12, label: "Tháng 12" }
                        ]} />
                    </div>

                    <div style={{ width: '150px', float: "left" }}>
                      <Select style={{ float: "left" }}
                        value={{ value: this.state.yearSelected, label: "Năm " + this.state.yearSelected }}
                        onChange={(e) => {
                          this.setState({ yearSelected: e.value })
                          localStorage.setItem("year", e.value);
                          this.props.onChangeYear(e.value)
                        }}
                        options={[
                          { value: 2020, label: "Năm 2020" },
                          { value: 2021, label: "Năm 2021" },
                          { value: 2022, label: "Năm 2022" },
                          { value: 2023, label: "Năm 2023" },
                          { value: 2024, label: "Năm 2024" },
                          { value: 2025, label: "Năm 2025" },
                        ]} />
                    </div>
                  </>
                  :
                  <>
                  </>
                }

              </div>

              <Button style={{ margin: "0px" }} color="danger" className="justify-content-end" onClick={() => this.logout()} >Đăng xuất</Button>

            </Container>
        }
      </Navbar >
    );
  }
}

export default Header;
