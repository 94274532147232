import * as React from "react";
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    FormGroup,
    Label,
    Form,
    Container
} from "reactstrap";
import logoXiaomi from "xiaomiLogo.png";

export class ComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { checked: false };
    }

    canvasEl;

    componentDidMount() {
        console.log(this.props)
    }

    handleCheckboxOnChange = () =>
        this.setState({ checked: !this.state.checked });

    setRef = (ref) => (this.canvasEl = ref);

    render() {
        return (
            <div style={{ margin: "8mm 12mm 8mm 12mm" }}>
                <div style={{ fontSize: "60px" }}>
                    Hướng dẫn: {this.props.data.fullName}
                </div>

                <div style={{ fontSize: "60px" }}>
                    Hướng dẫn: {this.props.data.fullName}
                </div>

                <div style={{ fontSize: "60px" }}>
                    Hướng dẫn: {this.props.data.fullName}
                </div>

                <div style={{ fontSize: "60px" }}>
                    Hướng dẫn: {this.props.data.fullName}
                </div>

                <div style={{ fontSize: "60px" }}>
                    Hướng dẫn: {this.props.data.fullName}
                </div>
            </div>
        );
    }

    textToHtml(html) {
        let arr = html.split(/<br\s*\/?>/i);
        return arr.reduce((el, a) => el.concat(a, <br />), []);
    }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
    // eslint-disable-line max-len
    return <ComponentToPrint ref={ref} text={props.text} />;
});
