import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";

class InvoiceService {

  getPrices(currentItem) {
    return axios.get(API_URL + "/sale/price?currentItem=" + currentItem, { headers: authHeader() });
  }

  postPrice(idProduct, data) {
    var config = {
      method: 'post',
      url: API_URL + '/sale/price/' + idProduct,
      headers: authHeader(),
      data: data
    };
    return axios(config)
  }


  getCart() {
    return axios.get(API_URL + "/sale/cart", { headers: authHeader() });
  }

  postCart(infoProduct) {
    var config = {
      method: 'post',
      url: API_URL + '/sale/cart',
      headers: authHeader(),
      data: infoProduct
    };

    console.log(config)

    return axios(config)
  }




  getProducts_Search(keyword) {
    return axios.get(API_URL + "/products?keyword=" + keyword, { headers: authHeader() });
  }

  postProducts(idProduct, print_title, print_desc, print_type, showWarran) {
    var config = {
      method: 'post',
      url: API_URL + '/products?idProduct=' + idProduct,
      headers: authHeader(),
      data: { print_title: print_title, print_desc: print_desc, print_type: print_type, showWarran: showWarran }
    };

    return axios(config)
  }
}

export default new InvoiceService();
