import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    InputGroup,
    FormGroup,
    Label
} from "reactstrap";

import TaiChinhService from "../services/taichinh.service"

import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders(props) {
    const [data, setData] = useState(null)
    const [ketToan, setKetToan] = useState([])

    const [tongDetNo, setDetNo] = useState(0)
    const [tongCuaHangNo, setCuaHangNo] = useState(0)

    //Form load
    useEffect(() => {
        loadData_LichSuChiTien()
    }, [])

    // Lịch sử chi tiền
    function loadData_LichSuChiTien() {
        TaiChinhService.getKetToanBen().then((result) => {
            setKetToan(result.data.data)
            console.log(result.data)
            setDetNo(result.data.tongDetThieu)
            setCuaHangNo(result.data.tongCuaHangThieu)
        })

        TaiChinhService.getKetToanBen_ThangNay(`${props.monthSelected}.${props.yearSelected}`).then((result) => {
            setData(result.data)
        })
    }

    function loadFillData_KetToanThang() {
        return ketToan.map((data, key) => {
            return (
                <tr key={key} style={{ borderBottom: "2px solid black", borderTop: "2px solid black" }} >
                    <td style={{ fontWeight: 'bold' }}>{data.date}</td>

                    <tr style={{ borderTop: "0px hidden" }}>
                        <td>Cửa hàng nợ: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.cuahangNo)}</td>
                    </tr>
                    <tr>
                        <td>Đẹt nợ: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detNo)}</td>
                    </tr>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col md="3">
                            <Card className="thongke">
                                <CardHeader>
                                    <CardTitle tag="h5">Kết toán {moment(`${props.monthSelected}-${props.yearSelected}`, 'MM-YYYY').format('MM/yyyy')}</CardTitle>
                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <tbody>
                                            <tr>
                                                <td>Bán lẻ tại cửa hàng: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daBan_Transfer) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Bán TMDT & GHTK: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daBan_TMDT) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Đã nhận từ Đẹt: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daNhan) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Đã chi nhập hàng: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.daChi) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>

                                            <tr>
                                                <td>Đẹt đang nợ: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detNo) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Cửa hàng đang nợ: </td>
                                                <td>{data ? new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.cuaHangNo) : <span class="spinner-border spinner-border-sm"></span>}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3" md="3" xs="12">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category">Tổng cửa hàng nợ</p>
                                                <CardTitle tag="p">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tongCuaHangNo)} </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3" md="3" xs="12">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category">Tổng đẹt nợ</p>
                                                <CardTitle tag="p">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tongDetNo)} </CardTitle>
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3" md="3" xs="12">
                            <Card className="taichinh">
                                <CardHeader className="function justify-content-between">
                                    <CardTitle tag="h5">Lịch sử kết toán</CardTitle>
                                </CardHeader>

                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Tháng</th>
                                                <th>Thông tin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadFillData_KetToanThang()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </div >
    )
}

export default Orders