import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";

class InvoiceService {

  getInvoices(fromPurchaseDate) {
    return axios.get(API_URL + "/invoices?fromPurchaseDate=" + fromPurchaseDate, { headers: authHeader() });
  }

  getInvoices_BanLe(fromPurchaseDate) {
    return axios.get(API_URL + "/invoices/banle?fromPurchaseDate=" + fromPurchaseDate, { headers: authHeader() });
  }

  getInvoices_GHTK(fromPurchaseDate) {
    return axios.get(API_URL + "/invoices/ghtk?fromPurchaseDate=" + fromPurchaseDate, { headers: authHeader() });
  }

  getInvoices_GHTK_Details(fromPurchaseDate) {
    return axios.get(API_URL + "/invoices/ghtk?fromPurchaseDate=" + fromPurchaseDate, { headers: authHeader() });
  }

  getInvoices_TMDT(fromPurchaseDate) {
    return axios.get(API_URL + "/invoices/tmdt?fromPurchaseDate=" + fromPurchaseDate, { headers: authHeader() });
  }

  getTransactions(total) {
    return axios.get(API_URL + "/transaction?total=" + total, { headers: authHeader() });
  }
}

export default new InvoiceService();
