import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    InputGroup,
    FormGroup,
    Label
} from "reactstrap";

import TienBanService from "../services/tienban.service"
import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function Orders(props) {
    const [tienban, setTienBan] = useState([])
    const [search, setSearch] = useState(null)
    const [thongke, setThongKe] = useState([])

    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState([])

    const [date, setDate] = useState(moment(new Date(props.yearSelected, props.monthSelected - 1, 1)).format("DD/MM/YYYY"))
    const [calendar, setCalendar] = useState(new Date(props.yearSelected, props.monthSelected - 1, 1));
    const [activeCalendar, setActiveCalendar] = useState(false)

    function selectACalendar(value) {
        const date = moment(value).toDate()
        setCalendar(date)

        const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        setDate(moment(newDate).format("DD/MM/YYYY"))
        setActiveCalendar(false)

        //getData(date)
    }

    const [soTien, setSoTien] = useState('')
    const [noiDung, setNoiDung] = useState('')


    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
        setActiveCalendar(false)
    }

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }


    useEffect(() => {
        getTienBan()
    }, [])


    function getTienBan() {
        TienBanService.getTienBan(props.monthSelected, props.yearSelected).then((result) => {
            setTienBan(result.data)


            // Thống kê nhân viên chi
            var thongke = []

            result.data.forEach(ele => {
                var isExist = false
                var index = 0
                var sotien = 0

                console.log(ele)

                for (var ik = 0; ik < thongke.length; ik++) {
                    if (thongke[ik].nguoidung == ele.nguoidung) {
                        index = ik
                        sotien = parseInt(thongke[ik].sotien)
                        isExist = true
                    }
                }

                if (isExist) {
                    sotien += parseInt(ele.sotien);
                    thongke[index] = {
                        nguoidung: ele.nguoidung,
                        sotien: sotien
                    }
                } else {
                    thongke.push({
                        nguoidung: ele.nguoidung,
                        sotien: parseInt(ele.sotien)
                    })
                }
            });

            setThongKe(thongke)
        })
    };

    function postTienBan() {
        TienBanService.postTienBan(soTien, noiDung, moment(date, "DD/MM/YYYY").add(7, 'hours').toDate())
            .then((result) => {
                getTienBan()

                setSoTien('')
                setNoiDung('')
                closeModal()

                Swal.fire({
                    icon: 'success',
                    title: result.data.message,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    // TaiChinhService.getNhanVien().then((result) => {
                    //     setNhanVien(result.data)
                    // })
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    //setModalShow(true)
                    //window.location.reload()
                })
            })
    };

    function deleteTienBan(id) {
        TienBanService.deleteTienBan(id)
            .then((result) => {
                getTienBan()

                Swal.fire({
                    icon: 'success',
                    title: result.data.message,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    // TaiChinhService.getNhanVien().then((result) => {
                    //     setNhanVien(result.data)
                    // })
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    //setModalShow(true)
                    //window.location.reload()
                })
            })
    };

    function loadFillData() {
        return tienban.filter((data) => {
            if (search == null) {
                return data
                // } else if (data.fullname.toLowerCase().includes(search.toLowerCase())) {
                //     return data
            }
        }).map((data, key) => {
            return (
                <tr key={key}>
                    <td style={{ fontWeight: 'bold' }}>{moment(data.createdAt).format('DD/MM/yyyy')}</td>
                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.sotien)}</td>
                    <td><Button className="btn btn-danger" onClick={() => deleteTienBan(data.id)}>Xóa</Button></td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col md="4">
                            <Card className="nhanvienchi">
                                <CardHeader className="function justify-content-between">
                                    <Row>
                                        <Col md="3" xs="5">
                                            <CardTitle tag="h5">Lịch sử</CardTitle>
                                        </Col>

                                        <Col md="6" xs="7">
                                            <Button className="btn btn-success float-right" onClick={openModal}>Thêm lịch sử đưa tiền</Button>
                                        </Col>

                                        <Col md="3" xs="12">
                                            <Input
                                                style={{ height: "38px", marginTop: "10px" }}

                                                value={search}
                                                onChange={(e) => searchSpace(e)}
                                                placeholder="Tìm kiếm" />
                                        </Col>
                                    </Row>
                                </CardHeader>


                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Ngày đưa</th>
                                                <th>Số tiền</th>
                                                <th>Xóa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadFillData()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Thêm lịch sử đưa tiền</h5>
                                <button onClick={closeModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <FormGroup>
                                    <Label for="exampleEmail">Ngày đưa</Label>
                                    <Input
                                        className="d-none d-sm-block"
                                        style={{ marginRight: "10px" }}
                                        onClick={() => {
                                            if (activeCalendar) {
                                                setActiveCalendar(false)
                                            } else {
                                                setActiveCalendar(true)
                                            }
                                        }}
                                        //onChange={(e) => setDate(e.target.value)}
                                        value={date}
                                        id="expired"
                                    />

                                    <div style={{ marginTop: "10px" }}>
                                        {
                                            activeCalendar
                                                ?
                                                <>
                                                    <Calendar
                                                        style={{ border: "0px solid #a0a096" }}
                                                        onChange={(e) => { selectACalendar(e) }}
                                                        value={calendar}
                                                    />
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="exampleEmail">Số tiền</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="exampleEmail"
                                        value={soTien}
                                        onChange={(e) =>
                                            setSoTien(e.target.value)
                                        }
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="examplePassword">Nội dung</Label>
                                    <Input
                                        type="text"
                                        name="text"
                                        id="examplePassword"
                                        value={noiDung}
                                        onChange={(e) => setNoiDung(e.target.value)}
                                    />
                                </FormGroup>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Hủy</button>
                                <button type="button" className="btn btn-primary" onClick={postTienBan}>Thêm</button>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </div>
    )
}

export default Orders