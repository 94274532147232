import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";

class BaoCaoChung {

  getBaoCaoChung(month, year) {
    return axios.get(API_URL + `/baocaothang/${month}.${year}`, { headers: authHeader() });
  }

  getNhanVienChi(month, year) {
    return axios.get(API_URL + `/nhanvienchi/${month}.${year}`, { headers: authHeader() });
  }

  getNhanVienChi_day(fromPurchaseDate) {
    return axios.get(API_URL + `/nhanvienchi?fromPurchaseDate=${fromPurchaseDate}`, { headers: authHeader() });
  }

  getPhiVanChuyen(month, year) {
    return axios.get(API_URL + `/phivanchuyen/${month}.${year}`, { headers: authHeader() });
  }

  postTatToanBen(month, year, benban, benchi) {
    var config = {
      method: 'post',
      url: API_URL + `/baocaothang/tattoanben?date=${month}/${year}`,
      headers: authHeader(),
      data: { benban: benban, benchi: benchi }
    };

    return axios(config)
  }

  postTatToanDet(month, year, detban, detchi) {
    var config = {
      method: 'post',
      url: API_URL + `/baocaothang/tattoandet?date=${month}/${year}`,
      headers: authHeader(),
      data: { detban: detban, detchi: detchi }
    };

    return axios(config)
  }
}

export default new BaoCaoChung();