import React, { useEffect } from 'react'
import { Button } from "reactstrap";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./BangGia";

function Orders(props) {
    const [loading, setLoading] = React.useState(false);
    const [text, setText] = React.useState("old boring text");
    const componentRef = React.useRef(null);
    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const marginTop = "1px"
    const marginRight = "1px"
    const marginBottom = "1px"
    const marginLeft = "1px"
    const getPageMargins = () => {
        return `
        @page {
            size: 210mm 297mm;
            margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft};
        } 
        `;
    };

    return (
        <div className="content">
            <style>{getPageMargins()}</style>

            <ReactToPrint
                content={reactToPrintContent}
                documentTitle="Bảng giá Xiaomi"
                trigger={() => {
                    return (
                        <Button className="btn btn-success">
                            {loading ? <span className="spinner-border spinner-border-sm"></span> : <>  In Bảng Giá </>}
                        </Button>
                    )
                }}
            />

            <ComponentToPrint ref={componentRef} text={text} data={props.data} />
        </div>
    )
}

export default Orders