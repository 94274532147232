import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://localhost:8080";
const API_URL = "https://api.mihomebmt.com";

class NhanVienSerivce {

  getNhanVien() {
    return axios.get(API_URL + "/nhanvien", { headers: authHeader() });
  }

  postNhanVien(username, password, fullname, role) {
    var config = {
      method: 'post',
      url: API_URL + '/nhanvien',
      headers: authHeader(),
      data: { username: username, password: password, fullname: fullname, role: role }
    };

    return axios(config)
  }

  deleteNhanVien(username) {
    return axios.delete(API_URL + '/nhanvien/' + username, { headers: authHeader() })
  }
}

export default new NhanVienSerivce();
