import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    InputGroup,
    FormGroup,
    Label
} from "reactstrap";

import NhanVienService from "../services/nhanvien.service"
import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'
import nhanvienService from '../services/nhanvien.service';

function Orders() {
    const [nhanVien, setNhanVien] = useState([])
    const [search, setSearch] = useState(null)
    const [page, setPage] = useState('')
    const [dataProps, setDataProps] = useState([])

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [fullname, setFullname] = useState('')
    const [role, setRole] = useState('')
    
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    useEffect(() => {
        NhanVienService.getNhanVien().then((result) => {
            setNhanVien(result.data)
        })
    }, [])

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }
    function loadFillData() {
        return nhanVien.filter((data) => {
            if (search == null) {
                return data
            } else if (data.fullname.toLowerCase().includes(search.toLowerCase())) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr key={key}>
                    <td>{data.username}</td>
                    <td>{data.fullname}</td>
                    <td>{data.role}</td>
                    <td>{moment(data.createdAt).format('DD/MM/yyyy HH:mm:ss')}</td>
                    <td>{moment(data.updatedAt).format('DD/MM/yyyy HH:mm:ss')}</td>

                    <td><Button color="warning" onClick={() => {
                        setUsername(data.username)
                        setFullname(data.fullname)
                        setRole(data.role)
                        openModal()
                    }}>Sửa</Button></td>

                    <td><Button color="danger" onClick={(e) => deleteNhanVien(data.username)}>Xóa</Button></td>
                </tr>
            )
        })
    };

    function register() {
        setIsOpen(false);

        NhanVienService.postNhanVien(username, password, fullname, role)
            .then((result) => {
                Swal.fire({
                    icon: 'success',
                    title: result.data.message,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    NhanVienService.getNhanVien().then((result) => {
                        setNhanVien(result.data)
                    })
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    //setModalShow(true)
                    //window.location.reload()
                })
            })
    }

    function deleteNhanVien(username) {
        nhanvienService.deleteNhanVien(username)
            .then((result) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Xóa tài khoản thành công',
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    NhanVienService.getNhanVien().then((result) => {
                        setNhanVien(result.data)
                    })
                })
            })
            .catch(function (error) {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 2000
                }).then(res => {
                    //setModalShow(true)
                    //window.location.reload()
                })
            })
    }

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col md="8">
                            <Card className="nhanvienchi">
                                <CardHeader className="function justify-content-between">
                                    <CardTitle tag="h5">Nhân viên</CardTitle>
                                </CardHeader>

                                <CardHeader className="function justify-content-between">
                                    <Button className="btn btn-success" onClick={openModal}>Thêm nhân viên</Button>
                                    <Input
                                        style={{ height: "38px", marginTop: "7px" }}
                                        className="col col-lg-3 float-right"
                                        value={search}
                                        onChange={(e) => searchSpace(e)}
                                        placeholder="Tìm kiếm" />
                                </CardHeader>


                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Tên người dùng</th>
                                                <th>Họ tên</th>
                                                <th>Quyền</th>
                                                <th>Ngày tạo</th>
                                                <th>Ngày cập nhập</th>
                                                <th>Sửa</th>
                                                <th>Xóa</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadFillData()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                    <Modal className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Thêm nhân viên</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <FormGroup>
                                    <Label for="exampleEmail">Tên người dùng</Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        id="exampleEmail"
                                        value = {username}
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="examplePassword">Mật khẩu</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        id="examplePassword"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="fullname">Họ tên</Label>
                                    <Input
                                        type="fullname"
                                        name="fullname"
                                        id="fullname"
                                        value = {fullname}
                                        onChange={(e) =>
                                            setFullname(e.target.value)
                                        }
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="examplePassword">Role</Label>
                                    <Select
                                        placeholder={role}
                                        value={role}
                                        onChange={(e) =>
                                            setRole(e.value)
                                        }
                                        options={[
                                            { value: 'ADMIN', label: 'ADMIN' },
                                            { value: 'MANAGER', label: 'MANAGER' },
                                            { value: 'STAFF', label: 'STAFF' },
                                            { value: 'SELLER', label: 'SELLER' }
                                        ]}
                                    />
                                </FormGroup>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Hủy</button>
                                <button type="button" className="btn btn-primary" onClick={register}>Lưu thiết lập</button>
                            </div>
                        </div>
                    </Modal>

                </>
            }
        </div>
    )
}

export default Orders