import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = "http://192.168.10.156:8080";
const API_URL = "https://api.mihomebmt.com";

class NhapHangSerive {

  getPhieuNhap(month, year) {
    return axios.get(API_URL + `/tiennhaphang/${month}.${year}`, { headers: authHeader() });
  }

  postPhieuNhap(soTien, noiDung, dateCreate) {
    var config = {
      method: 'post',
      url: API_URL + '/tiennhaphang',
      headers: authHeader(),
      data: { sotien: soTien, noidung: noiDung, date: dateCreate }
    };

    return axios(config)
  }

  deletePhieuNhap(id) {
    var config = {
      method: 'delete',
      url: API_URL + '/tiennhaphang/' + id,
      headers: authHeader()
    };

    return axios(config)
  }
}

export default new NhapHangSerive();
