import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    FormGroup,
    Label,
    Form,
    CardFooter
} from "reactstrap";

import InvoiceSerivce from "../services/invoices.service"

import Select from 'react-select';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders(props) {
    const [orders, setOrders] = useState([])
    const [loading, setLoading] = useState(true)

    const [search, setSearch] = useState(null)

    
    const [details, setDetails] = useState(null)

    const [choXuLy, setChoXuLy] = useState(0)
    const [dangGiaoHang, setDangGiaoHang] = useState(0)
    const [giaoThanhCong, setGiaoThanhCong] = useState(0)
    const [huyVaChuyenHoan, setHuyVaChuyenHoan] = useState(0)

    const [saleChannel, setSaleChannel] = useState("Tất cả")
    const [totalDoiSoat, setTotalDoiSoat] = useState(0)

    const [modalIsOpen, setIsOpen] = useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function toDetailView(val) {
        setDetails(val)
        openModal()
    }

    useEffect(() => {
        getData(`${props.monthSelected}.${props.yearSelected}`)
    }, [])

    function getData(dateX) {
        InvoiceSerivce.getInvoices_TMDT(dateX).then((result) => {
            setOrders(result.data)
            setLoading(false)

            var choxuly = 0
            var danggiaohang = 0
            var giaothanhcong = 0
            var huyvachuyenhoan = 0
            var total = 0

            result.data.forEach(ele => {
                if (ele.invoiceDelivery.status == 3) giaothanhcong += 1
                if (ele.invoiceDelivery.status == 4) huyvachuyenhoan += 1
                if (ele.invoiceDelivery.status == 6) huyvachuyenhoan += 1
                if (ele.invoiceDelivery.status == 2) danggiaohang += 1
                if (ele.invoiceDelivery.status == 1) choxuly += 1

                if (saleChannel == "Tất cả") total += ele.total_doisoat

                if (saleChannel == "Lazada Đẹt") {
                    if (ele.saleChannelName == "Lazada Đẹt") total += ele.total_doisoat
                }

                if (saleChannel == "Lazada Ben") {
                    if (ele.saleChannelName == "Lazada Ben") total += ele.total_doisoat
                }

                if (saleChannel == "Shopee Đẹt") {
                    if (ele.saleChannelName == "Shopee Đẹt") total += ele.total_doisoat
                }

                if (saleChannel == "Shopee Ben") {
                    if (ele.saleChannelName == "Shopee Ben") total += ele.total_doisoat
                }

                if (saleChannel == "Sendo Đẹt") {
                    if (ele.saleChannelName == "Sendo Đẹt") total += ele.total_doisoat
                }

                if (saleChannel == "Sendo Ben") {
                    if (ele.saleChannelName == "Sendo Ben") total += ele.total_doisoat
                }
            });

            setChoXuLy(choxuly)
            setDangGiaoHang(danggiaohang)
            setGiaoThanhCong(giaothanhcong)
            setHuyVaChuyenHoan(huyvachuyenhoan)
            setTotalDoiSoat(total)
        })
    }

    function calTotalDoiSoat(saleChannel) {
        var total = 0

        orders.forEach(ele => {
            if (saleChannel == "Tất cả") total += ele.total_doisoat

            if (saleChannel == "Lazada Đẹt") {
                if (ele.saleChannelName == "Lazada Đẹt") total += ele.total_doisoat
            }

            if (saleChannel == "Lazada Ben") {
                if (ele.saleChannelName == "Lazada Ben") total += ele.total_doisoat
            }

            if (saleChannel == "Shopee Đẹt") {
                if (ele.saleChannelName == "Shopee Đẹt") total += ele.total_doisoat
            }

            if (saleChannel == "Shopee Ben") {
                if (ele.saleChannelName == "Shopee Ben") total += ele.total_doisoat
            }

            if (saleChannel == "Sendo Đẹt") {
                if (ele.saleChannelName == "Sendo Đẹt") total += ele.total_doisoat
            }

            if (saleChannel == "Sendo Ben") {
                if (ele.saleChannelName == "Sendo Ben") total += ele.total_doisoat
            }
        });

        setTotalDoiSoat(total)
    }

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }

    function loadFillData() {
        var lstData = orders.filter((data) => {

            var isOK = false

            if (saleChannel == "Tất cả") isOK = true

            if (saleChannel == "Lazada Đẹt") {
                if (data.saleChannelName == "Lazada Đẹt") {
                    isOK = true
                }
            }

            if (saleChannel == "Lazada Ben") {
                if (data.saleChannelName == "Lazada Ben") {
                    isOK = true
                }
            }

            if (saleChannel == "Shopee Đẹt") {
                if (data.saleChannelName == "Shopee Đẹt") {
                    isOK = true
                }
            }

            if (saleChannel == "Shopee Ben") {
                if (data.saleChannelName == "Shopee Ben") {
                    isOK = true
                }
            }

            if (saleChannel == "Sendo Đẹt") {
                if (data.saleChannelName == "Sendo Đẹt") {
                    isOK = true
                }
            }

            if (saleChannel == "Sendo Ben") {
                if (data.saleChannelName == "Sendo Ben") {
                    isOK = true
                }
            }

            // Đổ màu cho dòng
            data.color = ""
            if (data.invoiceDelivery.status == 3) data.color = "table-success"
            if (data.invoiceDelivery.status == 4) data.color = "table-danger"
            if (data.invoiceDelivery.status == 6) data.color = "table-danger"
            if (data.invoiceDelivery.status == 2) data.color = "table-warning"
            if (data.invoiceDelivery.status == 1) data.color = "table-secondary"

            if (isOK) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr className={data.color} style={{ cursor: "pointer" }} onClick={() => toDetailView(data)} key={key}>
                    <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{moment(data.date).format('DD/MM/YYYY HH:mm')}</td>
                    <td className="table-device-mobile">{data.saleChannelName}</td>
                    <td className="table-device-mobile">{data.customer}</td>
                    <td className="table-device-mobile">{data.invoiceDelivery.contactNumber}</td>
                    <td className="table-device-mobile">{data.partnerDelivery}</td>
                    <td className="table-device-mobile">{data.invoiceDelivery.deliveryCode}</td>
                    <td className="table-device-mobile">{data.invoiceDelivery.statusValue}</td>
                    <td className="table-device-mobile" >{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.total)}</td>
                    <td className="table-device-mobile" >{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.ship_money)}</td>
                    <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.total_doisoat)}</td>
                </tr>
            )
        })

        lstData.push(
            <tr >
                <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>Tổng cộng</td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile"></td>
                <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(totalDoiSoat)}</td>
            </tr>
        )


        return lstData
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-primary" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Chờ xử lý</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{choXuLy}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-compass-05 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Đang giao hàng</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{dangGiaoHang}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-globe text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Giao thành công</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{giaoThanhCong}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg="3" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-globe text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Hủy và trả hàng</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{huyVaChuyenHoan}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card className="nhanvienchi">
                                <CardHeader className="function justify-content-between">
                                    <Row>
                                        <Col md="6">
                                            <CardTitle tag="h5">Đơn hàng TMDT</CardTitle>
                                        </Col>

                                        <Col md="3">
                                            <Row>
                                                <span style={{ marginTop: "8px", marginRight: "15px", display: "inline-block" }} class="label label-default">Kênh bán:</span>
                                                <Select
                                                    className="flex-fill"
                                                    value={{ value: saleChannel, label: saleChannel }}
                                                    onChange={(e) => {
                                                        setSaleChannel(e.value)
                                                        calTotalDoiSoat(e.value)
                                                    }}
                                                    options={[
                                                        { value: "Tất cả", label: "Tất cả" },
                                                        { value: "Lazada Đẹt", label: "Lazada Đẹt" },
                                                        { value: "Lazada Ben", label: "Lazada Ben" },
                                                        { value: "Shopee Đẹt", label: "Shopee Đẹt" },
                                                        { value: "Shopee Ben", label: "Shopee Ben" },
                                                        { value: "Sendo Đẹt", label: "Sendo Đẹt" },
                                                        { value: "Sendo Ben", label: "Sendo Ben" }
                                                    ]} />
                                            </Row>
                                        </Col>

                                        <Col md="3">
                                            <Input
                                                style={{ height: "38px" }}

                                                value={search}
                                                onChange={(e) => searchSpace(e)}
                                                placeholder="Tìm kiếm" />
                                        </Col>
                                    </Row>

                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        {
                                            loading ? <span class="spinner-border spinner-border-sm"></span> :
                                                <>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Thời gian</th>
                                                            <th>Kênh bán</th>
                                                            <th>Khách hàng</th>
                                                            <th>Số điện thoại</th>
                                                            <th>Giao hàng</th>
                                                            <th>Mã vận đơn</th>
                                                            <th>Trạng thái</th>
                                                            <th>Tổng tiền</th>
                                                            <th>Phí</th>
                                                            <th>Đối soát</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loadFillData()}
                                                    </tbody>
                                                </>
                                        }

                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal size="lg" style={{ maxWidth: '900px', width: '100%' }} className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Chi tiết đơn hàng</h5>
                                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {details ?
                                    <Form>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Tên khách hàng</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.invoiceDelivery.receiver} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Số điện thoại</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.invoiceDelivery.contactNumber} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Địa chỉ</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.invoiceDelivery.address} />
                                            </div>
                                        </div>

                                        <br />
                                        <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Tên sản phẩm</th>
                                                    <th>Số lượng</th>
                                                    <th>Giá tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {details.invoiceDetails.map((data, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{data.productName}</td>
                                                            <td>{data.quantity}</td>
                                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.price)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Form>
                                    :
                                    <span class="spinner-border spinner-border-sm"></span>
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </div >
    )
}

export default Orders