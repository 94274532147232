import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    InputGroup,
    FormGroup,
    Label
} from "reactstrap";

import TaiChinhService from "../services/taichinh.service"

import Select from 'react-select';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders() {
    const [ketToan, setKetToan] = useState([])
    const [ketToanDetails, setKetToanDetails] = useState(null)

    const [loading, setLoading] = useState(true)
    const [khoanVay, setKhoanVay] = useState(0)

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal(data) {

        var details = <>
            <FormGroup row>
                <Label for="text" sm={3}>Ben bán</Label>
                <Col sm={9}>
                    <Input type="text" value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.benban)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="text" sm={3}>Ben nhập</Label>
                <Col sm={9}>
                    <Input type="text" value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.benchi)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="text" sm={3}>Đẹt bán</Label>
                <Col sm={9}>
                    <Input type="text" value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detban)} />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label for="text" sm={3}>Đẹt nhập</Label>
                <Col sm={9}>
                    <Input type="text" value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detchi)} />
                </Col>
            </FormGroup>
        </>


        setKetToanDetails(details)
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    //Form load
    useEffect(() => {
        loadData_KetToanThang()
    }, [])


    // Kết toán tháng
    function loadData_KetToanThang() {
        TaiChinhService.getKetToanThang().then((result) => {
            setKetToan(result.data.reverse())

            setLoading(false)

            var total = 0
            result.data.forEach(item => {
                total += (item.detchi + item.benchi - item.detban - item.benban)
            });

            setKhoanVay(total)
        })
    }

    function loadFillData_KetToanThang() {
        return ketToan.map((data, key) => {
            return (
                <tr key={key} style={{ cursor: "pointer" }} onClick={() => openModal(data)}>
                    <td style={{ fontWeight: 'bold' }}>{moment(data.createdAt).format('MM/yyyy')}</td>
                    <tr>
                        <td>Tổng bán: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detban + data.benban)}</td>
                    </tr>
                    <tr>
                        <td>Tổng nhập: </td>
                        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detchi + data.benchi)}</td>
                    </tr>
                    <tr>
                        <td>Vay tiền Ben: </td>
                        <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.detchi + data.benchi - data.detban - data.benban)}</td>
                    </tr>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col lg="3" md="3" sm="3">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="3" xs="3">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-bank text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="9" xs="9">
                                            <div className="numbers">
                                                <p className="card-category">Tổng vay từ ben</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(khoanVay)}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <Card className="taichinh">
                                <CardHeader className="function justify-content-between">
                                    <CardTitle tag="h5">Kết toán tháng</CardTitle>
                                </CardHeader>

                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        <thead className="text-primary">
                                            <tr>
                                                <th>Tháng</th>
                                                <th>Thống kê</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadFillData_KetToanThang()}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Chi tiết kết toán</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {ketToanDetails}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" data-dismiss="modal" onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </div >
    )
}

export default Orders