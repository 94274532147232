import React, { useEffect } from 'react'
import { Row } from "reactstrap";
import logoXiaomi from "xiaomiLogo.png";

function BangGiaPreview(props) {
    useEffect(() => {
        console.log(props)
    }, [])

    return (
        <div className='border rounded-top' style={{ height: "100mm", width: "90mm", backgroundColor: 'white', fontFamily: "Helvetica Neue" }}>
            <Row className="d-flex justify-content-center align-items-center"
                style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}
            >
                {textToHtml(props.detailProduct_title.replace(/\r?\n/g, '<br />'))}
            </Row>

            <Row
                className="d-flex justify-content-center align-items-center"
                style={{ height: "23mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}
            >
                {textToHtml(props.detailProduct_desc.replace(/\r?\n/g, '<br />'))}
            </Row>

            <Row
                className="d-flex justify-content-center align-items-center"
                style={{ fontStyle: "italic", height: "20mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "60px", lineHeight: "1.2", color: "red", fontWeight: 900 }}
            >
                SALE
            </Row>

            <Row
                className="d-flex justify-content-center align-items-center"
                style={{
                    height: "10mm",
                    backgroundColor: 'white',
                    marginRight: "0px",
                    marginLeft: "0px",
                    fontSize: "35px",
                    lineHeight: "1.2",
                    color: "#3c3c3c",
                    fontWeight: 500
                }}
            >
                <span style={{
                    borderBottom: "2px solid #3c3c3c",
                    top: "-19px",
                    position: "relative"
                }}>
                    <span style={{
                        top: "19px",
                        position: "relative"
                    }}>
                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(props.detailProduct_price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                    </span>
                </span>
            </Row>

            <Row
                className="d-flex justify-content-center align-items-center"
                style={{ height: "13mm", marginBottom: "5mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "red", fontWeight: 500 }}
            >
                {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(props.detailProduct_priceSale)).replaceAll('₫', 'đ').replace(/\s/g, "")}
            </Row>

            <Row
                className="d-flex justify-content-center align-items-center border-top-0 rounded-bottom"
                style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}
            >
                <img style={{ height: "16px" }} src={logoXiaomi}></img>
            </Row>
        </div>
    )

    function textToHtml(html) {
        let arr = html.split(/<br\s*\/?>/i);
        return arr.reduce((el, a) => el.concat(a, <br />), []);
    }
}

export default BangGiaPreview