import * as React from "react";
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    FormGroup,
    Label,
    Form,
    Container
} from "reactstrap";
import logoXiaomi from "xiaomiLogo.png";

export class ComponentToPrint extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = { checked: false };
    }

    canvasEl;

    componentDidMount() {

    }

    handleCheckboxOnChange = () =>
        this.setState({ checked: !this.state.checked });

    setRef = (ref) => (this.canvasEl = ref);

    render() {
        return (
            <div className="border text-center" style={{ backgroundColor: 'white', fontFamily: "Helvetica Neue" }}>
                {this.loadBangGia()}
            </div >
        );
    }

    loadBangGia() {
        var lst_BangGia7x5 = []
        var lst_BangGia7x10 = []
        var lst_BangGia7x8 = []
        var lst_BangGia4x8 = []

        for (var i = 0; i < this.props.data.length; i++) {
            if (this.props.data[i].db.print_type == "Bảng giá 7x5") {
                lst_BangGia7x5.push(this.props.data[i]);
            } if (this.props.data[i].db.print_type == "Bảng giá cao 7x10") {
                lst_BangGia7x10.push(this.props.data[i]);
            } if (this.props.data[i].db.print_type == "Bảng giá sale 7x8") {
                lst_BangGia7x8.push(this.props.data[i]);
            } if (this.props.data[i].db.print_type == "Bảng giá treo 4x8") {
                console.log(this.props.data[i])
                lst_BangGia4x8.push(this.props.data[i]);
            }
        }

        var arrHTML = []

        //7x5
        for (var i = 0; i < lst_BangGia7x5.length; i += 3) {
            var count = 1
            if (i == 0) {
                if (lst_BangGia7x5.length > 3) {
                    count = 3
                } else {
                    count = lst_BangGia7x5.length
                }
            } else {
                count = lst_BangGia7x5.length % 3
            }

            arrHTML.push(this.geneRowBangGia7x5(lst_BangGia7x5, i, count))
        }

        //7x10
        for (var i = 0; i < lst_BangGia7x10.length; i += 3) {
            var count = 1
            if (i == 0) {
                if (lst_BangGia7x10.length > 3) {
                    count = 3
                } else {
                    count = lst_BangGia7x10.length
                }
            } else {
                count = lst_BangGia7x10.length % 3
            }

            arrHTML.push(this.geneRowBangGia7x10(lst_BangGia7x10, i, count))
        }

        //7x8
        for (var i = 0; i < lst_BangGia7x8.length; i += 3) {
            var count = 1
            if (i == 0) {
                if (lst_BangGia7x8.length > 3) {
                    count = 3
                } else {
                    count = lst_BangGia7x8.length
                }
            } else {
                count = lst_BangGia7x8.length % 3
            }

            arrHTML.push(this.geneRowBangGia7x8(lst_BangGia7x8, i, count))
        }

        //4x8
        for (var i = 0; i < lst_BangGia4x8.length; i += 2) {
            var count = 1
            if (i == 0) {
                if (lst_BangGia4x8.length > 2) {
                    count = 2
                } else {
                    count = lst_BangGia4x8.length
                }
            } else {
                if (lst_BangGia4x8.length > i + 1) {
                    count = 2
                } else {
                    count = 1
                }
            }

            arrHTML.push(this.geneRowBangGia4x8(lst_BangGia4x8, i, count))
        }

        return arrHTML
    }

    geneRowBangGia7x5(lstData, index, count) {
        if (count == 1) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row className="text-center"
                        style={{ height: "13mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "15mm", marginBottom: "3mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" style={{ backgroundColor: '#ff6700' }} >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>


            )
        } else if (count == 2) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row className="text-center"
                        style={{ height: "13mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "15mm", marginBottom: "3mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        } else if (count == 3) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center">
                            {this.textToHtml(lstData[index + 2].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row className="text-center"
                        style={{ height: "13mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center">
                            {this.textToHtml(lstData[index + 2].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "15mm", marginBottom: "3mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 2].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        }
    }

    geneRowBangGia7x10(lstData, index, count) {
        console.log(lstData)
        if (count == 1) {
            return (
                <>
                    <Row style={{ height: "23mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    {
                        lstData[index].db.showWarran
                            ?
                            <>
                                <Row className="text-center"
                                    style={{ height: "52mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>

                                <Row style={{ height: "17mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                </Row>

                                <Row style={{ height: "13mm", fontSize: "18px", fontWeight: 400, lineHeight: "1.3", backgroundColor: 'white', color: "red", marginBottom: "3mm", marginRight: "0px", marginLeft: "0px" }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        Bảo hành {lstData[index].warran} tháng
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row className="text-center"
                                    style={{ height: "57mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>

                                <Row style={{ height: "22mm", marginBottom: "6mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                </Row>
                            </>
                    }

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" style={{ backgroundColor: '#ff6700' }} >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        } else if (count == 2) {
            return (
                <>
                    <Row style={{ height: "23mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    {
                        lstData[index].db.showWarran
                            ?
                            <>
                                <Row className="text-center"
                                    style={{ height: "52mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>

                                <Row style={{ height: "17mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                </Row>

                                <Row style={{ height: "13mm", fontSize: "18px", fontWeight: 400, lineHeight: "1.3", backgroundColor: 'white', color: "red", marginBottom: "3mm", marginRight: "0px", marginLeft: "0px" }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        Bảo hành {lstData[index].warran} tháng
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        Bảo hành {lstData[index + 1].warran} tháng
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row className="text-center"
                                    style={{ height: "57mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>

                                <Row style={{ height: "22mm", marginBottom: "6mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                </Row>
                            </>
                    }

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        } else if (count == 3) {
            return (
                <>
                    <Row style={{ height: "23mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center">
                            {this.textToHtml(lstData[index + 2].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    {
                        lstData[index].db.showWarran
                            ?
                            <>
                                <Row className="text-center"
                                    style={{ height: "52mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 2].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>

                                <Row style={{ height: "17mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 2].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                </Row>

                                <Row style={{ height: "13mm", fontSize: "18px", fontWeight: 400, lineHeight: "1.3", backgroundColor: 'white', color: "red", marginBottom: "3mm", marginRight: "0px", marginLeft: "0px" }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        Bảo hành {lstData[index].warran} tháng
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        Bảo hành {lstData[index + 1].warran} tháng
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        Bảo hành {lstData[index + 2].warran} tháng
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row className="text-center"
                                    style={{ height: "57mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }}>

                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 2].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>

                                <Row style={{ height: "22mm", marginBottom: "6mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500 }}>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                                        {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                    <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                                        {this.textToHtml(lstData[index + 2].db.print_desc.replace(/\r?\n/g, '<br />'))}
                                    </Col>
                                </Row>
                            </>
                    }

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        }
    }

    geneRowBangGia7x8(lstData, index, count) {
        console.log(lstData)
        if (count == 1) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}
                    >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "19mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }} >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ fontStyle: "italic", height: "20mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "60px", lineHeight: "1.2", color: "red", fontWeight: 900 }} >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            SALE
                        </Col>
                    </Row>

                    <Row style={{
                        height: "10mm",
                        backgroundColor: 'white',
                        marginRight: "0px",
                        marginLeft: "0px",
                        fontSize: "35px",
                        lineHeight: "1.2",
                        color: "#3c3c3c",
                        fontWeight: 500
                    }}
                    >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            <span style={{
                                borderBottom: "2px solid #3c3c3c",
                                top: "-19px",
                                position: "relative"
                            }}>
                                <span style={{
                                    top: "19px",
                                    position: "relative"
                                }}>
                                    {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                </span>
                            </span>
                        </Col>

                    </Row>

                    <Row style={{ height: "13mm", marginBottom: "5mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "red", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" style={{ backgroundColor: '#ff6700' }} >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        } else if (count == 2) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "19mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }} >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ fontStyle: "italic", height: "20mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "60px", lineHeight: "1.2", color: "red", fontWeight: 900 }} >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            SALE
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            SALE
                        </Col>
                    </Row>

                    <Row style={{
                        height: "10mm",
                        backgroundColor: 'white',
                        marginRight: "0px",
                        marginLeft: "0px",
                        fontSize: "35px",
                        lineHeight: "1.2",
                        color: "#3c3c3c",
                        fontWeight: 500
                    }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            <span style={{
                                borderBottom: "2px solid #3c3c3c",
                                top: "-19px",
                                position: "relative"
                            }}>
                                <span style={{
                                    top: "19px",
                                    position: "relative"
                                }}>
                                    {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                </span>
                            </span>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            <span style={{
                                borderBottom: "2px solid #3c3c3c",
                                top: "-19px",
                                position: "relative"
                            }}>
                                <span style={{
                                    top: "19px",
                                    position: "relative"
                                }}>
                                    {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                </span>
                            </span>
                        </Col>
                    </Row>

                    <Row style={{ height: "13mm", marginBottom: "5mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "red", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        } else if (count == 3) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "5mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 2].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "19mm", fontSize: "16px", lineHeight: "1.3", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px" }} >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 1].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            {this.textToHtml(lstData[index + 2].db.print_desc.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ fontStyle: "italic", height: "20mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "60px", lineHeight: "1.2", color: "red", fontWeight: 900 }} >
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            SALE
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            SALE
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right">
                            SALE
                        </Col>
                    </Row>

                    <Row style={{
                        height: "10mm",
                        backgroundColor: 'white',
                        marginRight: "0px",
                        marginLeft: "0px",
                        fontSize: "35px",
                        lineHeight: "1.2",
                        color: "#3c3c3c",
                        fontWeight: 500
                    }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            <span style={{
                                borderBottom: "2px solid #3c3c3c",
                                top: "-19px",
                                position: "relative"
                            }}>
                                <span style={{
                                    top: "19px",
                                    position: "relative"
                                }}>
                                    {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                </span>
                            </span>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            <span style={{
                                borderBottom: "2px solid #3c3c3c",
                                top: "-19px",
                                position: "relative"
                            }}>
                                <span style={{
                                    top: "19px",
                                    position: "relative"
                                }}>
                                    {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                </span>
                            </span>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            <span style={{
                                borderBottom: "2px solid #3c3c3c",
                                top: "-19px",
                                position: "relative"
                            }}>
                                <span style={{
                                    top: "19px",
                                    position: "relative"
                                }}>
                                    {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 2].price)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                                </span>
                            </span>
                        </Col>

                    </Row>


                    <Row style={{ height: "13mm", marginBottom: "5mm", backgroundColor: 'white', marginRight: "0px", marginLeft: "0px", fontSize: "50px", lineHeight: "1.2", color: "red", fontWeight: 500 }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center text-center">
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 2].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center border-right" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="col-4 d-flex justify-content-center align-items-center" >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        }
    }

    geneRowBangGia4x8(lstData, index, count) {
        console.log(index, count)

        if (count == 1) {
            return (
                <>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "7mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ weight: "30mm", maxWidth: "38%" }} >
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "23mm", backgroundColor: 'white', fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500, marginRight: "0px", marginLeft: "0px" }} >
                        <Col className="col-5 d-flex justify-content-center align-items-center border-right" style={{ weight: "30mm", maxWidth: "38%" }}>
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="col-5 d-flex justify-content-center align-items-center border-right" style={{ backgroundColor: '#ff6700', weight: "30mm", maxWidth: "38%" }} >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </>
            )
        } else if (count == 2) {
            return (
                <div style={{}}>
                    <Row style={{ height: "15mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "7mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }}>
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ weight: "30mm", maxWidth: "38%" }}>
                            {this.textToHtml(lstData[index].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ weight: "30mm", maxWidth: "38%" }}>
                            {this.textToHtml(lstData[index + 1].db.print_title.replace(/\r?\n/g, '<br />'))}
                        </Col>
                    </Row>

                    <Row style={{ height: "23mm", backgroundColor: 'white', fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500, marginRight: "0px", marginLeft: "0px" }} >
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ weight: "30mm", maxWidth: "38%" }}>
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ weight: "30mm", maxWidth: "38%" }}>
                            {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(lstData[index + 1].salePrice)).replaceAll('₫', 'đ').replace(/\s/g, "")}
                        </Col>
                    </Row>

                    <Row id="Logo" className="border-bottom" style={{ height: "10mm", marginRight: "0px", marginLeft: "0px" }}>
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ backgroundColor: '#ff6700', weight: "30mm", maxWidth: "38%" }} >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center border-right" style={{ backgroundColor: '#ff6700', weight: "30mm", maxWidth: "38%" }} >
                            <img style={{ height: "16px" }} src={logoXiaomi}></img>
                        </Col>
                    </Row>
                </div>
            )
        }
    }


    textToHtml(html) {
        let arr = html.split(/<br\s*\/?>/i);
        return arr.reduce((el, a) => el.concat(a, <br />), []);
    }
}

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
    // eslint-disable-line max-len
    return <ComponentToPrint ref={ref} text={props.text} />;
});
