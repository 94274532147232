import React from "react";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table
} from "reactstrap";

import moment from 'moment'

// core components
import dashboardNASDAQChart from "variables/charts.js";
import BaoCao from '../services/baocao.service'

class Staff_Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      chart: dashboardNASDAQChart,

      baocao: null,
      thongketheongay: null
    }
  }

  componentDidMount() {
    BaoCao.getNhanVienChi(`${this.props.monthSelected}.${this.props.yearSelected}`)
      .then(response => {
        const lst_nhanvienchi = response.data;

        // Đổ dữ liệu phân tích
        var nhanvienchi = lst_nhanvienchi.map((data, key) => {
          return (
            <tr style={{ cursor: "pointer" }} key={key}>
              <th>{moment(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}</th>
              <td>{data.nguoichi}</td>
              <td>{data.noidung}</td>
              <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.sotien)}</td>
            </tr>
          )
        })

        var total = 0
        lst_nhanvienchi.forEach(ele => {
          total += ele.sotien
        });

        nhanvienchi.push(
          <tr className="totalRow" style={{ marginTop: "10px", lineHeight: "15px", borderTop: '2px solid', borderSpacing: '10px 15px', fontWeight: 'bold' }} >
            <td>Tổng cộng</td>
            <td></td>
            <td></td>
            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(total)}</td>
          </tr>
        )

        this.setState({ nhanvienchi: nhanvienchi })


        // Thống kê nhân viên chi
        var thongke = []

        lst_nhanvienchi.forEach(ele => {
          var isExist = false
          var index = 0
          var sotien = 0

          for (var ik = 0; ik < thongke.length; ik++) {
            if (thongke[ik].nguoichi == ele.nguoichi) {
              index = ik
              sotien = parseInt(thongke[ik].sotien)
              isExist = true
            }
          }

          if (isExist) {
            sotien += parseInt(ele.sotien);
            thongke[index] = {
              nguoichi: ele.nguoichi,
              sotien: sotien
            }
          } else {
            thongke.push({
              nguoichi: ele.nguoichi,
              sotien: parseInt(ele.sotien)
            })
          }
        });

        var thongke_NhanVienChi = thongke.map((data, key) => {
          return (
            <tr style={{ cursor: "pointer" }} key={key}>
              <td>{data.nguoichi}:</td>
              <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.sotien)}</td>
            </tr>
          )
        })

        this.setState({ thongke_NhanVienChi: thongke_NhanVienChi })

      }, error => {
        alert("Error đổ dữ liệu nhân viên: " + error)
      }

      ).catch(error => {
        alert("Catch đổ dữ liệu nhân viên: " + error)
      })
  }


  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="3">
              <Card className="thongke_NhanVienChi">
                <CardHeader>
                  <CardTitle tag="h5">Thống kê</CardTitle>
                </CardHeader>
                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                    {/* <thead className="text-primary">
                      <tr>
                        <th>Thời gian</th>
                        <th>Người chi</th>
                        <th className="table-device-mobile">Nội dung</th>
                        <th className="table-device-mobile">Số tiền</th>
                      </tr>
                    </thead> */}
                    <tbody>
                      {this.state.thongke_NhanVienChi}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card className="nhanvienchi">
                <CardHeader>
                  <CardTitle tag="h5">Nhân viên chi</CardTitle>
                </CardHeader>
                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>

                    <thead className="text-primary">
                      <tr>
                        <th>Thời gian</th>
                        <th>Người chi</th>
                        <th className="table-device-mobile">Nội dung</th>
                        <th className="table-device-mobile">Số tiền</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.nhanvienchi}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }

}

export default Staff_Dashboard;
