import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    CardFooter,
    Container,
    Label
} from "reactstrap";

import ReactToPrint from "react-to-print";

import ProductSerivce from "../services/products.service"

import Select from 'react-select';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

import { FiCheckCircle, FiEdit, FiXCircle, FiX, FiChevronsRight, FiPlus, FiCheck, FiPrinter } from "react-icons/fi";
import BangGia from '../components/Childrens/BangGiaFunc'
import logoXiaomi from "xiaomiLogo.png";

import BangGia7x5 from 'components/Childrens/BangGiaPreview/BangGia7x5';
import BangGia7x10 from 'components/Childrens/BangGiaPreview/BangGia7x10';
import BangGia7x8 from 'components/Childrens/BangGiaPreview/BangGia7x8';
import BangGia4x8 from 'components/Childrens/BangGiaPreview/BangGia4x8';

import { ComponentToPrint } from 'components/Childrens/HDSDPreView/HDSD10x15'

class Product extends React.Component {
    componentRef = null;

    constructor(props) {
        super(props);

        this.state = {
            products: [],

            search: null,
            showLoadSearch: false,

            modalIsOpen: false,
            detailProduct: null,
            detailProduct_type: "Bảng giá 7x5",
            detailProduct_priceSale: null,
            detailProduct_price: null,
            detailProduct_title: null,
            detailProduct_desc: null,

            detailProduct_warran_show: false,
            detailProduct_warran: 0,

            lstSelected: [],
            page: 'select',
            showLoadTruoc: false,
            showLoadSau: false,
            currentItem: 0,


            modalIsOpen_HDSD: false,
            details_HDSD: null,
            text: "old boring text"
        }
    }

    componentDidMount() {
        ProductSerivce.getProducts(this.state.currentItem).then((result) => {
            this.setState({ products: result.data })
        })
    }

    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.setState({ showLoadSearch: true })

            if (e.target.value != "") {
                ProductSerivce.getProducts_Search(e.target.value).then((result) => {
                    this.setState({ products: result.data })
                    this.setState({ showLoadSearch: false })
                })
            } else {
                ProductSerivce.getProducts(this.state.currentItem).then((result) => {
                    this.setState({ products: result.data })
                    this.setState({ showLoadSearch: false })
                })
            }
        }
    }

    addToSelected(val) {
        var tmp = this.state.lstSelected

        if (tmp.length > 14) {
            console.log("Thêm tối đa 15 sản phẩm")
        } else {
            // var isExist = false
            // tmp.forEach(ele => {
            //     if (ele.id == val.id) {
            //         isExist = true
            //     }
            // });

            // if (isExist) {
            //     console.log("Sản phẩm đã thêm")
            // } else {
            //     tmp.push(val)
            //     this.setState({ lstSelected: tmp })
            // }

            tmp.push(val)
            this.setState({ lstSelected: tmp })
        }
    }

    deleteToSelected(val) {
        var tmp = []
        this.state.lstSelected.forEach(ele => {
            if (ele.id != val.id) {
                tmp.push(ele)
            }
        });

        this.setState({ lstSelected: tmp })
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModal_HDSD() {
        this.setState({ modalIsOpen_HDSD: true });
    }

    closeModal_HDSD() {
        this.setState({ modalIsOpen_HDSD: false });
    }

    toDetailView(val) {

        this.setState({ detailProduct: val })
        this.setState({ detailProduct_price: val.price })
        this.setState({ detailProduct_priceSale: val.salePrice })

        if (val.db == null) {
            this.setState({ detailProduct_type: "Bảng giá 7x5" })
            this.setState({ detailProduct_title: val.fullName })
            this.setState({ detailProduct_desc: "" })
            this.setState({ detailProduct_warran_show: false })
            this.setState({ detailProduct_warran: 0 })
        } else {
            this.setState({ detailProduct_type: val.db.print_type })
            this.setState({ detailProduct_title: val.db.print_title })
            this.setState({ detailProduct_desc: val.db.print_desc })
            this.setState({ detailProduct_warran_show: val.db.showWarran })
            this.setState({ detailProduct_warran: val.warran })
        }

        this.openModal()
    }

    toDetailView_HDSD(val) {
        this.setState({ details_HDSD: val })
        this.openModal_HDSD()
    }

    updateDetails() {
        ProductSerivce.postProducts(this.state.detailProduct.id, this.state.detailProduct_title, this.state.detailProduct_desc, this.state.detailProduct_type, this.state.detailProduct_warran_show).then((result) => {
            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                timer: 600
            }).then(() => {
                var tmp = []
                this.state.products.forEach(item => {
                    if (item.id == this.state.detailProduct.id) {
                        item.db = {
                            print_title: this.state.detailProduct_title,
                            print_desc: this.state.detailProduct_desc,
                            print_type: this.state.detailProduct_type,
                            showWarran: this.state.detailProduct_warran_show
                        }
                    }

                    tmp.push(item)
                });

                this.setState({ products: tmp })
            })
        })
    }

    back() {
        return (

            <Button
                color="info"
                onClick={() => this.setState({ page: "" })}>
                &laquo; Quay lại
            </Button>

        )
    }

    render() {
        return (
            <>
                <div className="content">
                    {
                        this.state.page == 'details'
                            ?
                            <>
                                {this.back()}
                                <BangGia data={this.state.lstSelected} />
                            </>
                            :
                            <>
                                <Row>
                                    <Col md="7">
                                        <Card className="nhanvienchi">
                                            <CardHeader className="d-flex flex-row">
                                                <CardTitle className="col-8 justify-content-start" tag="h5">Danh sách sản phẩm</CardTitle>
                                                <Input
                                                    className="justify-content-end"
                                                    style={{ height: "35px", marginTop: "7px" }}
                                                    value={this.state.search}
                                                    onKeyDown={(e) => this.handleKeyDown(e)}
                                                    placeholder="Tìm kiếm" />
                                            </CardHeader>

                                            {
                                                this.state.showLoadSearch
                                                    ?
                                                    <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                        <div className='d-flex justify-content-center' style={{ height: "400px", fontSize: "40px" }}>
                                                            <span className="spinner-border spinner-border-sm align-self-center" style={{ width: "80px", height: "80px" }}></span>
                                                        </div>
                                                    </CardBody>
                                                    :
                                                    <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                        <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                                            <thead className="text-primary">
                                                                <tr>
                                                                    <th>HDSD</th>
                                                                    <th>Tên hàng</th>
                                                                    <th>Giá bán</th>
                                                                    <th>Loại</th>
                                                                    <th>Thêm</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {this.showProducts()}
                                                            </tbody>
                                                        </Table>
                                                    </CardBody>
                                            }

                                            <CardFooter>
                                                <nav className="pagination justify-content-center ">
                                                    <ul className="pagination">
                                                        <Button
                                                            style={{ marginRight: "10px" }}
                                                            color="info"
                                                            onClick={() => {
                                                                var index = this.state.currentItem - 20
                                                                if (index < 0) {
                                                                    Swal.fire({
                                                                        icon: 'error',
                                                                        title: 'Đây là trang đầu',
                                                                        showConfirmButton: false,
                                                                        timer: 1000
                                                                    })
                                                                    return
                                                                }

                                                                this.setState({ showLoadTruoc: true })
                                                                this.setState({ currentItem: index })

                                                                ProductSerivce.getProducts(index).then((result) => {
                                                                    this.setState({ products: result.data })
                                                                    this.setState({ showLoadTruoc: false })
                                                                })
                                                            }}>
                                                            {this.state.showLoadTruoc ? <span class="spinner-border spinner-border-sm"></span> : <> &laquo; Trang trước </>}

                                                        </Button>
                                                        <Button
                                                            color="info"
                                                            onClick={() => {
                                                                this.setState({ showLoadSau: true })

                                                                var index = this.state.currentItem + 20
                                                                this.setState({ currentItem: index })

                                                                ProductSerivce.getProducts(index).then((result) => {
                                                                    this.setState({ products: result.data })
                                                                    this.setState({ showLoadSau: false })
                                                                })
                                                            }}>
                                                            {this.state.showLoadSau ? <span class="spinner-border spinner-border-sm"></span> : <> Trang sau &raquo; </>}
                                                        </Button>
                                                    </ul>
                                                </nav>
                                            </CardFooter>
                                        </Card>
                                    </Col>

                                    <Col md="5">
                                        <Card className="tongket">
                                            <CardHeader>
                                                <CardTitle tag="h5">Sản phẩm đã chọn</CardTitle>
                                            </CardHeader>
                                            <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                                <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Tên hàng</th>
                                                            <th>Xóa</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.showSelectedProducts()}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                            <CardFooter>
                                                <Button className="btn-success" onClick={() => this.setState({ page: 'details' })}>Tạo bảng giá</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>


                                <Modal size="lg" style={{ maxWidth: '800px', width: '100%' }} className="modal-dialog modal-dialog-centered"
                                    isOpen={this.state.modalIsOpen}
                                    onRequestClose={() => this.closeModal()}
                                >
                                    <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                                        <div className="modal-header">
                                            <h5 className="modal-title">Chi tiết sản phẩm</h5>
                                            <button type="button" onClick={() => this.closeModal()} className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                this.state.detailProduct != null
                                                    ?
                                                    <Row style={{ margin: "5px 5px 25px 5px" }}>
                                                        <Col className="col-6">
                                                            <Form>
                                                                <div className="form-group row">
                                                                    <label className="col-5 col-form-label">Tiêu đề</label>
                                                                    <div className="col-12">
                                                                        <Select
                                                                            isSearchable={false}
                                                                            value={{ value: this.state.detailProduct_type, label: this.state.detailProduct_type }}
                                                                            onChange={(e) => {
                                                                                this.setState({ detailProduct_type: e.value })
                                                                                this.toggle()
                                                                            }}
                                                                            options={[
                                                                                { value: "Bảng giá 7x5", label: "Bảng giá 7x5" },
                                                                                { value: "Bảng giá sale 7x8", label: "Bảng giá sale 7x8" },
                                                                                { value: "Bảng giá cao 7x10", label: "Bảng giá cao 7x10" },
                                                                                { value: "Bảng giá treo 4x8", label: "Bảng giá treo 4x8" },
                                                                            ]} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-5 col-form-label">Tiêu đề</label>
                                                                    <div className="col-12">
                                                                        <textarea type="text"
                                                                            className="form-control-plaintext border rounded"
                                                                            style={{ paddingLeft: "10px" }}
                                                                            value={this.state.detailProduct_title}
                                                                            onChange={(e) => this.setState({ detailProduct_title: e.target.value })} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-5 col-form-label">Mô tả sản phẩm</label>
                                                                    <div className="col-12">
                                                                        <textarea type="text"
                                                                            className="form-control-plaintext border rounded"
                                                                            style={{ paddingLeft: "10px" }}
                                                                            value={this.state.detailProduct_desc}
                                                                            onChange={(e) => {
                                                                                this.setState({ detailProduct_desc: e.target.value })
                                                                            }} />
                                                                    </div>
                                                                </div>

                                                                <div className="form-group row">
                                                                    <label className="col-5 col-form-label">Giá bán</label>
                                                                    <div className="col-12">
                                                                        <Input type="text"
                                                                            className="form-control-plaintext"
                                                                            value={this.state.detailProduct_price}
                                                                            onChange={(e) => this.setState({ detailProduct_price: e.target.value })} />
                                                                    </div>
                                                                </div>

                                                                {
                                                                    this.state.detailProduct_type == "Bảng giá cao 7x10"
                                                                        ?
                                                                        <>
                                                                            <br />
                                                                            <div className="form-group row">
                                                                                <div style={{ marginLeft: "20px" }} className="col-12">
                                                                                    <Input type="checkbox" id="defaultCheck1" defaultChecked={this.state.detailProduct_warran_show} onChange={(e) => this.setState({ detailProduct_warran_show: e.target.checked })} />
                                                                                    <Label for="defaultCheck1">
                                                                                        Hiển thị thời gian bảo hành
                                                                                    </Label>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }

                                                                <br />
                                                            </Form>
                                                        </Col>

                                                        <Col className="col-6 d-flex justify-content-center align-items-center text-center">
                                                            {
                                                                this.state.detailProduct_type == "Bảng giá 7x5"
                                                                    ?
                                                                    <BangGia7x5
                                                                        detailProduct_type={this.state.detailProduct_type}
                                                                        detailProduct_title={this.state.detailProduct_title}
                                                                        detailProduct_desc={this.state.detailProduct_desc}
                                                                        detailProduct_priceSale={this.state.detailProduct_priceSale}
                                                                        detailProduct_price={this.state.detailProduct_price} />
                                                                    :
                                                                    this.state.detailProduct_type == "Bảng giá cao 7x10"
                                                                        ?
                                                                        <BangGia7x10
                                                                            detailProduct_type={this.state.detailProduct_type}
                                                                            detailProduct_title={this.state.detailProduct_title}
                                                                            detailProduct_desc={this.state.detailProduct_desc}
                                                                            detailProduct_price={this.state.detailProduct_price}
                                                                            detailProduct_priceSale={this.state.detailProduct_priceSale}
                                                                            detailProduct_warran={this.state.detailProduct_warran}
                                                                            detailProduct_warran_show={this.state.detailProduct_warran_show} />
                                                                        :
                                                                        this.state.detailProduct_type == "Bảng giá sale 7x8"
                                                                            ?
                                                                            <BangGia7x8
                                                                                detailProduct_type={this.state.detailProduct_type}
                                                                                detailProduct_title={this.state.detailProduct_title}
                                                                                detailProduct_desc={this.state.detailProduct_desc}
                                                                                detailProduct_price={this.state.detailProduct_price}
                                                                                detailProduct_priceSale={this.state.detailProduct_priceSale}
                                                                                detailProduct_warran={this.state.detailProduct_warran}
                                                                                detailProduct_warran_show={this.state.detailProduct_warran_show} />
                                                                            :
                                                                            this.state.detailProduct_type == "Bảng giá treo 4x8"
                                                                                ?
                                                                                <BangGia4x8
                                                                                    detailProduct_type={this.state.detailProduct_type}
                                                                                    detailProduct_title={this.state.detailProduct_title}
                                                                                    detailProduct_desc={this.state.detailProduct_desc}
                                                                                    detailProduct_price={this.state.detailProduct_price}
                                                                                    detailProduct_priceSale={this.state.detailProduct_priceSale}
                                                                                    detailProduct_warran={this.state.detailProduct_warran}
                                                                                    detailProduct_warran_show={this.state.detailProduct_warran_show} />
                                                                                :
                                                                                <>
                                                                                </>
                                                            }

                                                        </Col>
                                                    </Row>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-success" onClick={() => {
                                                this.closeModal()
                                                this.updateDetails()
                                            }}>Save</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.closeModal()}>Close</button>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal size="lg" className="modal-dialog modal-dialog-centered"
                                    isOpen={this.state.modalIsOpen_HDSD}
                                    onRequestClose={() => this.closeModal_HDSD()}
                                >
                                    <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                                        <div className="modal-header">
                                            <h5 className="modal-title">In hướng dẫn sử dụng</h5>
                                            <button type="button" onClick={() => this.closeModal_HDSD()} className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            {
                                                this.state.details_HDSD != null
                                                    ?
                                                    <Row style={{ margin: "5px 5px 25px 5px" }}>
                                                        <Col className="col-6">
                                                            <Form>
                                                                <div className="form-group row">
                                                                    <label className="col-5 col-form-label">Mô tả sản phẩm</label>
                                                                    <div className="col-12">
                                                                        {/* <textarea type="text"
                                                                            className="form-control-plaintext border rounded"
                                                                            style={{ paddingLeft: "10px" }}
                                                                            value={this.state.detailProduct_desc}
                                                                            onChange={(e) => {
                                                                                this.setState({ detailProduct_desc: e.target.value })
                                                                            }} /> */}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </Form>
                                                        </Col>

                                                        <Col
                                                            className="col-6 d-flex border"
                                                        >
                                                            <ComponentToPrint ref={this.setComponentRef} data={this.state.details_HDSD} />
                                                        </Col>
                                                    </Row>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="modal-footer">


                                            <ReactToPrint
                                                content={this.reactToPrintContent}
                                                documentTitle="Bảng giá Xiaomi"
                                                trigger={() => {
                                                    return (
                                                        <Button className="btn btn-success">
                                                            In Bảng Giá
                                                        </Button>
                                                    )
                                                }}
                                            />
                                            <button type="button" className="btn btn-success" onClick={() => {
                                                this.closeModal_HDSD()
                                                this.updateDetails()
                                            }}>Save</button>
                                            <button type="button" className="btn btn-danger" onClick={() => this.closeModal_HDSD()}>Close</button>
                                        </div>
                                    </div>
                                </Modal>
                            </>
                    }
                </div>

            </>
        )
    }

    showProducts() {
        return this.state.products.map((data, key) => {
            return (
                <tr className={data.color} key={key} >

                    <td>
                        <FiPrinter className="text-success" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { this.toDetailView_HDSD(data) }} />
                    </td>

                    <td className="table-device-mobile"
                        style={{ cursor: "pointer" }}
                        onClick={() => { this.toDetailView(data) }}>
                        {data.fullName}
                        {data.db != null ? <FiCheck className="text-success" style={{ marginLeft: "3px", fontSize: "18px" }} /> : <FiX className="text-danger" style={{ marginLeft: "3px", fontSize: "18px" }} />}
                    </td>

                    <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.salePrice)} </td>
                    <td>{data.db != null ? data.db.print_type : <>Bảng giá 7x5</>}</td>
                    <td>
                        <FiPlus className="text-success" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => {
                            if (data.db != null) {
                                this.addToSelected(data)
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: "Vui lòng chỉnh sửa thông tin trước khi thêm",
                                    showConfirmButton: true,
                                    timer: 2000
                                })
                            }
                        }} />
                    </td>
                </tr>
            )
        })
    };

    showSelectedProducts() {
        return this.state.lstSelected.map((data, key) => {
            return (
                <tr className={data.color} style={{ cursor: "pointer" }} key={key}>
                    <td className="table-device-mobile">{data.fullName}</td>
                    <td><FiX className="text-danger" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => this.deleteToSelected(data)}></FiX></td>
                </tr>
            )
        })
    };
}

export default Product;