import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    FormGroup,
    Label,
    Form
} from "reactstrap";

import InvoiceSerivce from "../services/invoices.service"

import Select from 'react-select';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

function Orders(props) {
    const [orders, setOrders] = useState([])
    const [search, setSearch] = useState(null)
    const [loading, setLoading] = useState(true)

    const [modalIsOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState(null)

    const [tongDonHang, setTongDonHang] = useState(0)
    const [chuyenKhoan, setChuyenKhoan] = useState(0)
    const [cod, setCOD] = useState(0)

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function toDetailView(val) {
        setDetails(val)
        openModal()
    }

    useEffect(() => {
        getData(`${props.monthSelected}.${props.yearSelected}`)
    }, [])

    function getData(dateX) {
        InvoiceSerivce.getInvoices_GHTK(dateX).then((result) => {
            setOrders(result.data)
            setLoading(false)

            var tongdonhang = 0
            var chuyenkhoan = 0
            var cod = 0

            result.data.forEach(ele => {
                tongdonhang += 1
                if ((ele.statusValue == "Đã đối soát") || (ele.statusValue == "Delivered")) {
                    if (ele.PTTT == "Chuyển khoản") chuyenkhoan += ele.total_doisoat
                    if (ele.PTTT == "COD") cod += ele.total_doisoat
                }
            });

            setTongDonHang(tongdonhang)
            setChuyenKhoan(chuyenkhoan)
            setCOD(cod)
        })
    }

    function searchSpace(event) {
        let keyword = event.target.value;
        setSearch(keyword)
    }

    function loadFillData() {
        return orders.filter((data) => {
            if (search == null) {
                data.color = ""
                if (data.PTTT == "Lỗi") data.color = "table-danger"

                if (data.statusValue == "Đã đối soát") data.color = "table-success"
                if (data.statusValue == "Delivered") data.color = "table-success"
                if (data.statusValue.includes("Đang lấy hàng")) data.color = "table-secondary"
                if (data.statusValue.includes("Đang giao hàng")) data.color = "table-warning"
                if (data.statusValue.includes("Không giao được hàng")) data.color = "table-danger"

                return data
            } else if (data.customer.toLowerCase().includes(search.toLowerCase())) {
                return data
            }
        }).map((data, key) => {
            return (
                <tr className={data.color} style={{ cursor: "pointer" }} onClick={() => toDetailView(data)} key={key}>
                    <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{moment(data.date).format('DD/MM/YYYY HH:mm')}</td>
                    <td className="table-device-mobile">{data.soldByName}</td>
                    <td className="table-device-mobile">{data.customer}</td>
                    <td className="table-device-mobile">{data.PTTT}</td>
                    <td className="table-device-mobile">{data.isFreeship}</td>
                    <td className="table-device-mobile">{data.deliveryCode}</td>
                    <td className="table-device-mobile">{data.statusValue}</td>
                    <td className="table-device-mobile" >{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.total)}</td>
                    <td className="table-device-mobile" >{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.ship_money)}</td>
                    <td className="table-device-mobile" style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.total_doisoat)}</td>
                </tr>
            )
        })
    };

    return (
        <div className="content">
            {
                <>
                    <Row>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-money-coins text-primary" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Tổng đơn hàng</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{tongDonHang}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-compass-05 text-warning" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Chuyển khoản</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(chuyenKhoan)}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg="4" md="6" sm="6">
                            <Card className="card-stats">
                                <CardBody>
                                    <Row>
                                        <Col md="4" xs="5">
                                            <div className="icon-big text-center icon-warning">
                                                <i className="nc-icon nc-globe text-danger" />
                                            </div>
                                        </Col>
                                        <Col md="8" xs="7">
                                            <div className="numbers">
                                                <p className="card-category">Thu COD</p>
                                                {loading ? <span class="spinner-border spinner-border-sm"></span> : <CardTitle tag="p">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(cod)}</CardTitle>}
                                                <p />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <Card className="nhanvienchi">
                                <CardHeader className="function justify-content-between">
                                    <CardTitle tag="h5">Đơn hàng GHTK</CardTitle>
                                </CardHeader>
                                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                        {
                                            loading ? <span class="spinner-border spinner-border-sm"></span> :
                                                <>
                                                    <thead className="text-primary">
                                                        <tr>
                                                            <th>Thời gian</th>
                                                            <th>Nhân viên bán</th>
                                                            <th>Khách hàng</th>
                                                            <th className="table-device-mobile">PTTT</th>
                                                            <th className="table-device-mobile">Freeship</th>
                                                            <th className="table-device-mobile">Mã vận đơn</th>
                                                            <th className="table-device-mobile">Trạng thái</th>
                                                            <th className="table-device-mobile">Tổng tiền</th>
                                                            <th className="table-device-mobile">Phí</th>
                                                            <th className="table-device-mobile">Đối soát</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loadFillData()}
                                                    </tbody>
                                                </>
                                        }
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal size="lg" style={{ maxWidth: '900px', width: '100%' }} className="modal-dialog modal-dialog-centered"
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
                            <div className="modal-header">
                                <h5 className="modal-title">Chi tiết đơn hàng</h5>
                                <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {details ?
                                    <Form>
                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Tên khách hàng</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.invoiceDelivery.receiver} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Số điện thoại</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.invoiceDelivery.contactNumber} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-2 col-form-label">Địa chỉ</label>
                                            <div className="col-sm-10">
                                                <Input type="text" readonly className="form-control-plaintext" value={details.address} />
                                            </div>
                                        </div>

                                        <br />
                                        <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Tên sản phẩm</th>
                                                    <th>Số lượng</th>
                                                    <th>Giá tiền</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {details.invoiceDetails.map((data, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{data.productName}</td>
                                                            <td>{data.quantity}</td>
                                                            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.price)}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Form>
                                    :
                                    null
                                }
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={closeModal}>OK</button>
                            </div>
                        </div>
                    </Modal>
                </>
            }
        </div>
    )
}

export default Orders