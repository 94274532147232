import React from "react";
// react plugin used to create charts
import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Modal,
  Button,
  CardFooter,
  FormGroup,
  Input,
  Label
} from "reactstrap";

// core components
import dashboardNASDAQChart from "variables/charts.js";
import BaoCao from '../services/baocao.service'

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      chart: dashboardNASDAQChart,
      baocao: null,
      thongketheongay: null,

      monthSelected: this.props.monthSelected,
      yearSelected: this.props.yearSelected,

      details: null,
      modalIsOpen: false,

      TTDetails: null,
      modalTatToanIsOpen: false,
      typeTatToan: null
    }
  }

  loadBaoCao(month, year) {
    BaoCao.getBaoCaoChung(month, year)
      .then(response => {
        const dataX = response.data;

        // Đổ dữ liệu chart
        var cKey = []
        var cValue = []

        var cValue_BanLe_CashCard = []
        var cValue_TMDT_Det = []

        var cValue_BanLe_Transfer = []
        var cValue_TMDT_Ben = []

        var banle = 0
        var tmdt_det = 0
        var ghtk_det = 0
        var tmdt_ben = 0
        var ghtk_ben = 0

        dataX.ThongKeTheoNgay.slice(0).reverse().forEach(ele => {
          banle += ele.retails
          tmdt_det += ele.tmdt_det
          ghtk_det += ele.ghtk_det
          tmdt_ben += ele.tmdt_ben
          ghtk_ben += ele.ghtk_ben

          cKey.push(ele.day)
          cValue_BanLe_CashCard.push(ele.retails)
          cValue_TMDT_Det.push(ele.tmdt_det + ele.ghtk_det)

          cValue_BanLe_Transfer.push(ele.retails_transfer)
          cValue_TMDT_Ben.push(ele.tmdt_ben + ele.ghtk_ben)
        });

        const datachart = {
          data: {
            labels: cKey,
            datasets: [
              {
                label: "Đẹt bán lẻ",
                data: cValue_BanLe_CashCard,
                fill: true,
                borderColor: "#264653",
                backgroundColor: "transparent",
                pointBorderColor: "#264653",
                pointRadius: 3,
                pointHoverRadius: 3,
                pointBorderWidth: 6,
              },
              {
                label: "Đẹt bán online",
                data: cValue_TMDT_Det,
                fill: true,
                borderColor: "#2a9d8f",
                backgroundColor: "transparent",
                pointBorderColor: "#2a9d8f",
                pointRadius: 3,
                pointHoverRadius: 3,
                pointBorderWidth: 6,
              },
              {
                label: "Ben bán lẻ",
                data: cValue_BanLe_Transfer,
                fill: true,
                borderColor: "#e76f51",
                backgroundColor: "transparent",
                pointBorderColor: "#e76f51",
                pointRadius: 3,
                pointHoverRadius: 3,
                pointBorderWidth: 6,
              },
              {
                label: "Ben bán online",
                data: cValue_TMDT_Ben,
                fill: true,
                borderColor: "#f4a261",
                backgroundColor: "transparent",
                pointBorderColor: "#f4a261",
                pointRadius: 3,
                pointHoverRadius: 3,
                pointBorderWidth: 6,
              }
            ],
          },
          options: {
            legend: {
              display: true,
              position: "top",
            },
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  callback: function (value, index, values) {
                    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(value)
                  }
                }
              }]
            },
            tooltips: {
              callbacks: {
                label: function (t, d) {
                  var xLabel = d.datasets[t.datasetIndex].label;
                  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(t.yLabel);
                }
              }
            },
            responsive: true
          },
        };

        this.setState({ chart: datachart })


        // Đổ dữ liệu phân tích
        var thongketheongay = dataX.ThongKeTheoNgay.slice(0).reverse().map((data, key) => {
          return (
            <tr style={{ cursor: "pointer" }} key={key}>
              <th >{data.day}</th>
              <td >{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.retails)}</td>
              <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.tmdt_det)}</td>
              <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.ghtk_det)}</td>
              <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.tmdt_ben)}</td>
              <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.ghtk_ben)}</td>
            </tr>

          )
        })

        thongketheongay.push(
          <tr className="totalRow" style={{ marginTop: "10px", lineHeight: "15px", borderTop: '2px solid', borderSpacing: '10px 15px', fontWeight: 'bold' }} >
            <td>Tổng cộng</td>
            <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(banle)}</td>
            <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tmdt_det)}</td>
            <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(ghtk_det)}</td>
            <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tmdt_ben)}</td>
            <td className="d-none d-lg-block">{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(ghtk_ben)}</td>
          </tr>
        )

        this.setState({ thongketheongay: thongketheongay })
        this.setState({ baocao: dataX })


      }, error => {
        alert("Lỗi, đổ dữ liệu báo cáo : " + error)
      }

      ).catch(error => {
        alert("Catch đổ dữ liệu báo cáo : " + error)
      })
  }

  componentDidMount() {
    this.loadBaoCao(this.state.monthSelected, this.state.yearSelected)
  }

  reloadData(month, year) {
    this.loadBaoCao(month, year)
  }

  openModal(type) {

    var lst = []
    var tong = 0
    var isShowDesc = false

    if (type == "ThongKeTheoNgay") {
      this.state.baocao.ThongKeTheoNgay.forEach(ele => {
        if (ele.retails > 0) {
          lst.push({ key: ele.day, value: ele.retails })
          tong += ele.retails
        }
      });

      lst = lst.reverse()
    }

    if (type == "ThongKeTheoNgayTransfer") {
      this.state.baocao.ThongKeTheoNgay.forEach(ele => {
        if (ele.retails_transfer > 0) {
          lst.push({ key: ele.day, value: ele.retails_transfer })
          tong += ele.retails_transfer
        }
      });

      lst = lst.reverse()
    }

    if (type == "TongTienDaDuaBen") {
      this.state.baocao.BaoCaoDet.TongTien_DaDuaBen_lst.forEach(ele => {
        if (ele.sotien > 0) {
          lst.push({ key: ele.date, value: ele.sotien })
          tong += ele.sotien
        }
      });
    }

    if (type == "TongTienNhapHang") {
      this.state.baocao.BaoCaoBen.TongTien_NhapHang_lst.forEach(ele => {
        isShowDesc = true
        lst.push({ key: ele.date, value: ele.sotien, desc: ele.desc })
        tong += ele.sotien
      });
    }

    if (type == "TongTienBenBan") {
      this.state.baocao.BaoCaoBen.TongTien_DaBan_lst.forEach(ele => {
        lst.push({ key: ele.type, value: ele.sotien })
        tong += ele.sotien
      });
    }

    if (type == "TongTienDetBan") {
      this.state.baocao.BaoCaoDet.TongTien_DaBan_lst.forEach(ele => {
        lst.push({ key: ele.type, value: ele.sotien })
        tong += ele.sotien
      });
    }

    if (type == "TongTienDetChi") {
      this.state.baocao.BaoCaoDet.TongTien_DaChi_lst.forEach(ele => {
        isShowDesc = true
        lst.push({ key: ele.date, value: ele.sotien, desc: ele.desc })
        tong += ele.sotien
      });
    }

    var details = lst.map((data, key) => {
      return (
        <tr key={key}>
          <td>{data.key}</td>
          <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.value)}</td>
          {
            isShowDesc
              ?
              <td>{data.desc}</td>
              :
              null
          }
        </tr>
      )
    })

    details.push(
      <tr style={{ fontWeight: 'bold', borderTop: "2px solid black" }}>
        <td>Tổng cộng</td>
        <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(tong)}</td>
        {
          isShowDesc
            ?
            <td>Nội dung</td>
            :
            null
        }
      </tr>
    )

    this.setState({ details: details })
    this.setState({ modalIsOpen: true });
  }

  openTatToanModal(type) {
    var details = null
    this.setState({ typeTatToan: type })

    if (type == "Ben") {
      details = (<>
        <FormGroup>
          <Label for="text">Tổng tiền Ben bán:</Label>
          <Input type="text" name="text" id="text" value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_DaBan)} />
        </FormGroup>
        <FormGroup>
          <Label for="text">Tổng tiền Ben nhập:</Label>
          <Input type="text" name="text" id="text" value={new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_NhapHang)} />
        </FormGroup>
      </>)
    } else {
      details = (<>
        <FormGroup>
          <Label for="text">Tổng tiền Đẹt bán:</Label>
          <Input type="text" name="text" id="text" value={this.state.baocao.BaoCaoDet.TongTien_DaBan} />
        </FormGroup>
        <FormGroup>
          <Label for="text">Tổng tiền Đẹt nhập:</Label>
          <Input type="text" name="text" id="text" value={this.state.baocao.BaoCaoDet.TongTien_DaChi} />
        </FormGroup>
      </>)
    }

    this.setState({ TTDetails: details })
    this.setState({ modalTatToanIsOpen: true });
  }

  postTatToan() {
    if (this.state.typeTatToan == "Ben") {
      BaoCao.postTatToanBen(this.state.monthSelected, this.state.yearSelected, this.state.baocao.BaoCaoBen.TongTien_DaBan, this.state.baocao.BaoCaoBen.TongTien_NhapHang)
        .then(result => {
          Swal.fire({
            icon: 'success',
            title: result.data.message,
            showConfirmButton: true,
            timer: 2000
          })
        })
        .catch(function (error) {
          Swal.fire({
            icon: 'error',
            title: error,
            showConfirmButton: true,
            timer: 2000
          })
        })
    } else if (this.state.typeTatToan == "Đẹt") {
      BaoCao.postTatToanDet(this.state.monthSelected, this.state.yearSelected, this.state.baocao.BaoCaoDet.TongTien_DaBan, this.state.baocao.BaoCaoDet.TongTien_DaChi)
        .then(result => {
          Swal.fire({
            icon: 'success',
            title: result.data.message,
            showConfirmButton: true,
            timer: 2000
          })
        })
        .catch(function (error) {
          Swal.fire({
            icon: 'error',
            title: error,
            showConfirmButton: true,
            timer: 2000
          })
        })

    }
  }


  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="3">
              <Card className="thongketheongay">
                <CardHeader>
                  <CardTitle tag="h5">Tiền Đẹt bán</CardTitle>
                </CardHeader>
                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                    {
                      this.state.baocao ?

                        <tbody>
                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("ThongKeTheoNgay")}>
                            <td style={{ fontWeight: 'bold' }} >Bán lẻ ({this.state.baocao.Retail}):</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalRetail)}</td>
                          </tr>

                          {
                            this.state.baocao.Shopee_Det > 0
                              ?
                              <tr>
                                <td style={{ fontWeight: 'bold' }}>Shopee ({this.state.baocao.Shopee_Det}):</td>
                                <tr>
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalShopee_Det)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Shopee_Det_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Shopee_Det_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }

                          {
                            this.state.baocao.Lazada_Det > 0
                              ?
                              <tr>
                                <td style={{ fontWeight: 'bold' }}>Lazada ({this.state.baocao.Lazada_Det}):</td>
                                <tr>
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalLazada_Det)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Lazada_Det_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Lazada_Det_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }

                          {
                            this.state.baocao.Sendo_Det > 0
                              ?
                              <tr>
                                <td style={{ fontWeight: 'bold' }}>Sendo ({this.state.baocao.Sendo_Det}):</td>
                                <tr>
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalSendo_Det)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Sendo_Det_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Sendo_Det_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }

                          {
                            this.state.baocao.GHTKCK_Det > 0
                              ?
                              <tr>
                                <td style={{ fontWeight: 'bold' }}>GHTK CK ({this.state.baocao.GHTKCK_Det}):</td>
                                <tr>
                                  <td>Tổng tiền: </td>
                                  <td >{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalGHTKCK_Det)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.GHTK_Det_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.GHTK_Det_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }


                        </tbody>

                        : <span class="spinner-border spinner-border-sm"></span>
                    }
                  </Table>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card className="thongketheongay">
                <CardHeader>
                  <CardTitle tag="h5">Tiền Ben bán</CardTitle>
                </CardHeader>
                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                    {
                      this.state.baocao ?
                        <tbody>
                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("ThongKeTheoNgayTransfer")}>
                            <td style={{ fontWeight: 'bold' }} >Bán lẻ ({this.state.baocao.Retail_Transfer}):</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalRetail_Transfer)}</td>
                          </tr>

                          {
                            this.state.baocao.Shopee_Ben > 0
                              ?
                              <tr >
                                <td style={{ fontWeight: 'bold' }}>Shopee ({this.state.baocao.Shopee_Ben}):</td>
                                <tr >
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalShopee_Ben)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Shopee_Ben_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Shopee_Ben_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }

                          {
                            this.state.baocao.Lazada_Ben > 0
                              ?
                              <tr  >
                                <td style={{ fontWeight: 'bold' }} >Lazada ({this.state.baocao.Lazada_Ben}):</td>
                                <tr >
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalLazada_Ben)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Lazada_Ben_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Lazada_Ben_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }

                          {
                            this.state.baocao.Sendo_Ben > 0
                              ?
                              <tr  >
                                <td style={{ fontWeight: 'bold' }} >Sendo ({this.state.baocao.Sendo_Ben}):</td>
                                <tr >
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalSendo_Ben)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Sendo_Ben_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.Sendo_Ben_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }

                          {
                            this.state.baocao.GHTKCOD_Ben > 0
                              ?
                              <tr  >
                                <td style={{ fontWeight: 'bold' }} >GHTK COD ({this.state.baocao.GHTKCOD_Ben}):</td>
                                <tr >
                                  <td>Tổng tiền: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.TotalGHTKCOD_Ben)}</td>
                                </tr>
                                <tr>
                                  <td>Phí: </td>
                                  <td>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.GHTK_Ben_TongPhi)}</td>
                                </tr>
                                <tr>
                                  <td>Doanh thu: </td>
                                  <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.GHTK_Ben_DoanhThu)}</td>
                                </tr>
                              </tr>
                              : null
                          }
                        </tbody>

                        : <span class="spinner-border spinner-border-sm"></span>
                    }
                  </Table>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card className="baocaodet">
                <CardHeader>
                  <CardTitle tag="h5">Tất toán Đẹt</CardTitle>
                </CardHeader>

                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                    {
                      this.state.baocao ?

                        <tbody>
                          <tr style={{ cursor: "pointer" }} >
                            <td>Tổng đơn đã bán:</td>
                            <td style={{ fontWeight: 'bold' }}>{this.state.baocao.BaoCaoDet.TongDon_DaBan}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("TongTienDetBan")}>
                            <td>Tổng tiền đã bán:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoDet.TongTien_DaBan)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("TongTienDetChi")}>
                            <td>Tổng tiền đã chi:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoDet.TongTien_DaChi)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("TongTienDaDuaBen")}>
                            <td>Tổng tiền đã đưa ben:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoDet.TongTien_DaDuaBen)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} >
                            <td>Tổng tiền còn thiếu ben:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoDet.TongTien_ConThieuBen)}</td>
                          </tr>
                        </tbody>

                        : <span class="spinner-border spinner-border-sm"></span>
                    }
                  </Table>
                </CardBody>
                <CardFooter>
                  {
                    this.state.baocao
                      ?
                      <Button className="btn btn-success" onClick={() => this.openTatToanModal("Đẹt")}>Tất toán phần Đẹt</Button>
                      : null
                  }
                </CardFooter>
              </Card>
            </Col>

            <Col md="3">
              <Card className="baocaoben">
                <CardHeader>
                  <CardTitle tag="h5">Tất toán Ben</CardTitle>
                </CardHeader>
                <CardBody style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                    {
                      this.state.baocao ?

                        <tbody>
                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("TongTienDaDuaBen")}>
                            <td>Tổng tiền đã nhận:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_DaNhan)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("TongTienBenBan")}>
                            <td>Tổng tiền đã bán:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_DaBan)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} onClick={() => this.openModal("TongTienNhapHang")} >
                            <td>Tổng tiền nhập hàng:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_NhapHang)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} >
                            <td>Tổng tiền đẹt thiếu ben:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_DetThieuBen)}</td>
                          </tr>

                          <tr style={{ cursor: "pointer" }} >
                            <td>Tổng tiền cửa hàng thiếu ben:</td>
                            <td style={{ fontWeight: 'bold' }}>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(this.state.baocao.BaoCaoBen.TongTien_CuaHangNoBen)}</td>
                          </tr>
                        </tbody>

                        : <span class="spinner-border spinner-border-sm"></span>
                    }
                  </Table>
                </CardBody>
                <CardFooter>
                  {
                    this.state.baocao
                      ?
                      <Button className="btn btn-success" onClick={() => this.openTatToanModal("Ben")}>Tất toán phần Ben</Button>
                      : null
                  }
                </CardFooter>
              </Card>
            </Col>
          </Row>

          <Row className="d-none d-lg-block">
            <Col md="12">
              <Card className="card-chart">
                <CardHeader>
                  <CardTitle tag="h5">Biểu đồ dòng tiền</CardTitle>
                </CardHeader>
                <CardBody>
                  <Line
                    data={this.state.chart.data}
                    options={this.state.chart.options}
                    width={400}
                    height={150}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Modal size="md" style={{ width: '100%' }} className="modal-dialog modal-dialog-centered"
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => { this.setState({ modalIsOpen: false }) }}
          >
            <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
              <div className="modal-header">
                <h5 className="modal-title">Chi tiết</h5>
                <button type="button" onClick={() => { this.setState({ modalIsOpen: false }) }} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {
                  this.state.details
                    ?
                    <Table className="table-hover" style={{ marginTop: "10px", lineHeight: "15px" }}>
                      <thead className="text-primary">
                        <tr>
                          <th>Ngày</th>
                          <th>Tổng tiền</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.details}
                      </tbody>
                    </Table>
                    :
                    null
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => { this.setState({ modalIsOpen: false }) }}>OK</button>
              </div>
            </div>
          </Modal>




          <Modal size="md" style={{ width: '100%' }} className="modal-dialog modal-dialog-centered"
            isOpen={this.state.modalTatToanIsOpen}
            onRequestClose={() => { this.setState({ modalTatToanIsOpen: false }) }}
          >
            <div className="modal-content" style={{ border: "0px", borderRadius: "20px" }} >
              <div className="modal-header">
                <h5 className="modal-title">Tất toán</h5>
                <button type="button" onClick={() => { this.setState({ modalTatToanIsOpen: false }) }} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {this.state.TTDetails}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" onClick={() => { this.setState({ modalTatToanIsOpen: false }) }}>Hủy</button>
                <button type="button" className="btn btn-primary" onClick={() => {
                  this.setState({ modalTatToanIsOpen: false })
                  this.postTatToan()
                }}>Lưu phiếu tất toán</button>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }

}

export default Dashboard;
