import React, { useEffect } from 'react'
import { Row } from "reactstrap";
import logoXiaomi from "xiaomiLogo.png";

function BangGiaPreview(props) {
    useEffect(() => {
        console.log(props)
    }, [])

    return (
        <div className='border rounded-top' style={{ height: "50mm", width: "100mm", backgroundColor: 'white', fontFamily: "Helvetica Neue" }}>
            <Row className="d-flex justify-content-center align-items-center"
                style={{ height: "20mm", fontSize: "25px", lineHeight: "1.2", backgroundColor: 'white', marginTop: "2mm", marginRight: "0px", marginLeft: "0px", fontWeight: 500 }} >
                {textToHtml(props.detailProduct_title.replace(/\r?\n/g, '<br />'))}
            </Row>

            <Row
                className="d-flex justify-content-center align-items-center"
                style={{ height: "15mm", backgroundColor: 'white', fontSize: "50px", lineHeight: "1.2", color: "#008aff", fontWeight: 500, marginBottom: "3mm", marginRight: "0px", marginLeft: "0px" }} >
                {(new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(props.detailProduct_priceSale)).replaceAll('₫', 'đ').replace(/\s/g, "")}
            </Row>
            <Row
                className="d-flex justify-content-center align-items-center border-top-0 rounded-bottom"
                style={{ height: "10mm", backgroundColor: '#ff6700', marginRight: "0px", marginLeft: "0px" }} >
                <img style={{ height: "16px" }} src={logoXiaomi}></img>
            </Row>

        </div>
    )

    function textToHtml(html) {
        let arr = html.split(/<br\s*\/?>/i);
        return arr.reduce((el, a) => el.concat(a, <br />), []);
    }
}

export default BangGiaPreview