import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    Table,
    Col,
    Input,
    Button,
    CardTitle,
    Row,
    CardHeader,
    Modal,
    Form,
    CardFooter,
    Container,
    Label
} from "reactstrap";

import ReactToPrint from "react-to-print";

import GiaSiService from "../services/giasi.service"

import Select from 'react-select';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import moment from 'moment'

import { FiCheckCircle, FiEdit, FiXCircle, FiX, FiChevronsRight, FiPlus, FiDelete, FiPrinter } from "react-icons/fi";
import BangGia from '../components/Childrens/BangGiaFunc'
import logoXiaomi from "xiaomiLogo.png";

import BangGia7x5 from 'components/Childrens/BangGiaPreview/BangGia7x5';
import BangGia7x10 from 'components/Childrens/BangGiaPreview/BangGia7x10';
import BangGia7x8 from 'components/Childrens/BangGiaPreview/BangGia7x8';
import BangGia4x8 from 'components/Childrens/BangGiaPreview/BangGia4x8';

import { ComponentToPrint } from 'components/Childrens/HDSDPreView/HDSD10x15'

class Product extends React.Component {
    componentRef = null;

    constructor(props) {
        super(props);

        this.state = {
            carts: [],

            search: null,
            showLoadSearch: false,

            modalIsOpen: false,
            detailProduct: null,
            detailProduct_type: "Bảng giá 7x5",
            detailProduct_priceSale: null,
            detailProduct_price: null,
            detailProduct_title: null,
            detailProduct_desc: null,

            detailProduct_warran_show: false,
            detailProduct_warran: 0,

            lstSelected: [],
            page: 'select',
            showLoadTruoc: false,
            showLoadSau: false,
            currentItem: 0,


            modalIsOpen_HDSD: false,
            details_HDSD: null,
            text: "old boring text",

            addToCart_SelectProduct: null,
            addToCart_Quantity: 1,
        }
    }

    componentDidMount() {
        GiaSiService.getCart().then((result) => {
            this.setState({ carts: result.data })
        })
    }

    setComponentRef = (ref) => {
        this.componentRef = ref;
    };

    reactToPrintContent = () => {
        return this.componentRef;
    };

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.setState({ showLoadSearch: true })

            // if (e.target.value != "") {
            //     ProductSerivce.getProducts_Search(e.target.value).then((result) => {
            //         this.setState({ products: result.data })
            //         this.setState({ showLoadSearch: false })
            //     })
            // } else {
            //     ProductSerivce.getProducts(this.state.currentItem).then((result) => {
            //         this.setState({ products: result.data })
            //         this.setState({ showLoadSearch: false })
            //     })
            // }
        }
    }

    addToSelected(val) {
        var tmp = this.state.lstSelected

        if (tmp.length > 14) {
            console.log("Thêm tối đa 15 sản phẩm")
        } else {
            // var isExist = false
            // tmp.forEach(ele => {
            //     if (ele.id == val.id) {
            //         isExist = true
            //     }
            // });

            // if (isExist) {
            //     console.log("Sản phẩm đã thêm")
            // } else {
            //     tmp.push(val)
            //     this.setState({ lstSelected: tmp })
            // }

            tmp.push(val)
            this.setState({ lstSelected: tmp })
        }
    }

    deleteToSelected(val) {
        var tmp = []
        this.state.lstSelected.forEach(ele => {
            if (ele.id != val.id) {
                tmp.push(ele)
            }
        });

        this.setState({ lstSelected: tmp })
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModal_HDSD() {
        this.setState({ modalIsOpen_HDSD: true });
    }

    closeModal_HDSD() {
        this.setState({ modalIsOpen_HDSD: false });
    }

    toDetailView(val) {

        this.setState({ detailProduct: val })
        this.setState({ detailProduct_price: val.price })
        this.setState({ detailProduct_priceSale: val.salePrice })

        if (val.db == null) {
            this.setState({ detailProduct_type: "Bảng giá 7x5" })
            this.setState({ detailProduct_title: val.fullName })
            this.setState({ detailProduct_desc: "" })
            this.setState({ detailProduct_warran_show: false })
            this.setState({ detailProduct_warran: 0 })
        } else {
            this.setState({ detailProduct_type: val.db.print_type })
            this.setState({ detailProduct_title: val.db.print_title })
            this.setState({ detailProduct_desc: val.db.print_desc })
            this.setState({ detailProduct_warran_show: val.db.showWarran })
            this.setState({ detailProduct_warran: val.warran })
        }

        this.openModal()
    }

    toDetailView_HDSD(val) {
        this.setState({ addToCart_SelectProduct: val })
        this.openModal_HDSD()
    }

    updateDetails() {
        console.log(this.state.addToCart_SelectProduct)

        var data = {
            idProduct: this.state.addToCart_SelectProduct.id,
            quantity: this.state.addToCart_Quantity,
            name: this.state.addToCart_SelectProduct.fullName
        }


        if (this.state.addToCart_SelectProduct.data) {
            data.price_1 = this.state.addToCart_SelectProduct.data.price_1
            data.price_1_pcs = this.state.addToCart_SelectProduct.data.price_1_pcs
            data.price_2 = this.state.addToCart_SelectProduct.data.price_2
            data.price_2_pcs = this.state.addToCart_SelectProduct.data.price_2_pcs
            data.warranty = this.state.addToCart_SelectProduct.data.warranty
        }

        console.log(data)

        GiaSiService.postCart(data).then((result) => {
            Swal.fire({
                icon: result.data.success ? 'success' : 'error',
                title: result.data.message,
                timer: 600
            })
        })
    }



    render() {
        return (
            <div className="content">
                <Row>
                    <Col md="8" className="">
                        <Card>
                            {/* <CardHeader>
                            </CardHeader> */}

                            {
                                this.state.showLoadSearch
                                    ?
                                    <CardBody>
                                        <div className='d-flex justify-content-center' style={{ height: "400px", fontSize: "40px" }}>
                                            <span className="spinner-border spinner-border-sm align-self-center" style={{ width: "80px", height: "80px" }}></span>
                                        </div>
                                    </CardBody>
                                    :
                                    <CardBody >
                                        <Table className="table-hover" >
                                            <thead className="text-primary">
                                                <tr>
                                                    <th>Tên sản phẩm</th>
                                                    <th className='text-center'>Số lượng</th>
                                                    <th className='text-center'>Đơn giá</th>
                                                    <th className='text-center'>Tiền hàng</th>
                                                    <th className='text-center'>Xóa</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.showProducts()}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                            }

                            <CardFooter>
                                <Button className="btn btn-success" onClick={() => this.openTatToanModal("Đẹt")}>Gửi đơn hàng</Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }


    showProducts() {
        return this.state.carts.map((data, key) => {
            return (
                <tr className={data.color} key={key} >

                    <td className="table-device-mobile">{data.name}</td>

                    <td>
                        <input
                            type="number"
                            class="form-control"
                            placeholder="1"
                            value={data.quantity}
                        // onChange={(e) => this.setState({ addToCart_Quantity: e.target.value })}
                        ></input>
                    </td>


                    <td className='text-center'>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.price_1)} </td>
                    <td className='text-center'>{new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(data.price_1)} </td>

                    <td className='text-center'>
                        {/* <FiDelete className="text-danger" style={{ cursor: "pointer", fontSize: "25px" }} onClick={() => { this.toDetailView_HDSD(data) }} /> */}
                    </td>

                </tr>
            )
        })
    };
}

export default Product;